[class$='btn_link'] {
  display: inline-block;
  height: 56px;
  padding: 0 44px;
  background-color: #000;
  font-size: 16px;
  line-height: 56px;
  color: #fff;
  @include border-radius(30.5px);
  @include box-sizing;
  @include vender-prefix(transition, background-color 0.2s);

  @include tablet {
    font-size: 15px;
    padding: 0 47px;
  }

  @include mobile {
    min-width: 172px;
    height: 46px;
    font-size: 14px;
    padding: 0 29px 0 37px;
    line-height: 46px;
  }

  @include hover {
    background-color: #1ace5e;
  }

  &.bd_btn_link {
    line-height: 54px;
    border: 1px solid #585858;
    background-color: transparent;

    @include hover {
      border: none;
      background-color: #1ace5e;
      line-height: 56px;
    }

    @include mobile {
      line-height: 44px;
    }
  }

  &.none_bg_btn_link {
    min-width: 105px;
    height: 44px;
    line-height: 44px;
    padding: 0 24px;
    color: #333;
    font-size: 16px;
    letter-spacing: -0.5px;
    background-color: #fff;
    .on & {
      border: none;
      color: #fff;
      background-color: #1ace5e;
    }
    @include hover {
      border: none;
    }

    @include mobile {
      min-width: auto;
      padding: 0px;
      font-size: 15px;
      letter-spacing: -0.54px;
      border-radius: 0;
      .on & {
        color: #1ace5e;
        background-color: #fff;
      }
    }
  }
}

.txt_btn {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #333;

  &:before {
    content: '';
    display: inline-block;
    margin-right: 13px;
    vertical-align: middle;
  }

  &.btn_return {
    &:before {
      @include sp-desktop('ico_return_list');

      @include mobile {
        @include sp-mobile('ico_return_list');
      }
    }

    @include hover {
      &:before {
        @include sp-desktop('ico_return_list_hover');
      }
    }
  }

  &.btn_prev {
    &:before {
      @include sp-desktop('ico_list_prev');
    }

    @include hover {
      &:before {
        @include sp-desktop('ico_list_prev_hover');
      }
    }
  }

  &.btn_next {
    margin-left: 20px;

    &:before {
      display: none;
    }

    &:after {
      content: '';
      display: inline-block;
      margin-left: 13px;
      vertical-align: middle;
      @include sp-desktop('ico_list_next');
    }

    @include hover {
      &:after {
        @include sp-desktop('ico_list_next_hover');
      }
    }
  }
}

.dropdown_box {
  position: relative;
  width: 180px;

  @include mobile {
    width: 120px;
  }

  .btn_dropdown {
    position: relative;
    display: block;
    width: 100%;
    padding: 2px 0 11px;
    border-bottom: 3px solid #333;
    font-size: 16px;
    line-height: 18px;
    text-align: left;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 15px;
      @include sp-desktop('ico_dropdown');

      @include mobile {
        bottom: 13px;
      }
    }

    &.on {
      & + .dropdown_list {
        display: block;
      }

      &:after {
        @include sp-desktop('ico_dropdown_on');
      }
    }
  }

  .dropdown_list {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: -1px;
    border: 1px solid #333;
    background-color: #f7f7f7;
    @include vender-prefix(transform, translateY(100%));
    @include box-sizing;
    z-index: 10;

    .item {
      + .item {
        border-top: 1px solid #333;
      }

      &:hover {
        background-color: #1ace5e;

        .link {
          color: #fff;
        }
      }
    }

    .link {
      display: block;
      padding: 0 16px;
      font-size: 15px;
      color: #333;
      line-height: 47px;
    }
  }
}

.btn_download {
  display: inline-block;
  position: relative;
  padding-left: 29px;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: -0.5px;
  text-decoration: underline;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    @include sp-desktop('ico_download');
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  @include hover {
    color: #1ace5e;
  }
}

.btn_more {
  @include sp-desktop('ico_more');

  @include hover {
    @include sp-desktop('ico_more_hover');
  }

  @include mobile {
    @include sp-mobile('ico_more');
  }
}

.btn_share {
  @include sp-mobile('ico_share');
}

.ly_popup {
  .btn_close {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 10px;

    @include tablet {
      top: 12px;
      right: 22px;
    }

    @include mobile {
      top: 15px;
      right: 14px;
    }

    &:before {
      content: '';
      display: block;
      @include sp-desktop('ico_popup_close');

      @include media-query(1279) {
        @include sp-mobile('ico_close');
      }
    }
  }
}
