#footer {
  margin-top: auto;
  padding: 52px 0 51px;
  background-color: #f9f9f9;
  font-size: 14px;
  color: #9e9e9e;
  @include box-sizing;

  @include mobile {
    padding: 27px 0 39px;
  }

  .fnb {
    @include clear;
    @include mobile {
      margin-top: 32px;
    }

    .item {
      float: left;

      @include media-query(365) {
        font-size: 12px;
        line-height: 16px;
      }

      @include media-query(865) {
        margin-top: 12px;
      }

      & + .item {
        margin-left: 24px;

        @include media-query(425) {
          &:last-child {
            display: block;
            width: 100%;
            margin-left: 0;
          }
        }

        @include media-query(365) {
          margin-left: 16px;
        }

        @include mobile {
          margin-top: 12px;
        }
      }

      &.copyright {
        position: relative;
        font-family: Calibre;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;

        @include media-query(865) {
          display: block;
          width: 100%;
          margin-top: 0;
        }

        @include mobile {
          display: block;
          width: 100%;
        }

        @include media-query(360) {
          font-size: 15px;
          margin-left: 0;
        }

        & + .item {
          margin-left: 32px;

          @include media-query(865) {
            margin-left: 0;
          }
          @include mobile {
            margin-left: 0;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 2px;
          right: -18px;
          width: 1px;
          height: 12px;
          background-color: #ccc;

          @include media-query(865) {
            display: none;
          }

          @include mobile {
            display: none;
          }

          @include media-query(360) {
            display: none;
          }
        }
      }
    }
  }

  address {
    margin-top: 25px;

    @include mobile {
      margin-top: 28px;
    }
  }

  .corp_info {
    font-size: 0;
    @include clear;

    dt,
    dd {
      display: inline-block;
      font-weight: 700;
      font-size: 14px;
      line-height: 23px;

      @include media-query(1279) {
        &.address:last-of-type {
          display: block;
          margin-left: 0;
        }
      }

      @include media-query(360) {
        font-size: 12px;
        letter-spacing: -0.5px;
      }
    }

    dt {
      &:not(.blind) {
        margin-left: 13px;

        &:first-of-type {
          margin-left: 0;
        }

        & + dd {
          margin-left: 0;
        }
      }
    }

    dd {
      margin-left: 15px;

      &:first-of-type {
        margin-left: 0;
      }

      @include mobile {
        margin-left: 0;
      }
    }
  }

  .family_site {
    position: absolute;
    top: -12px;
    right: 0;

    @include media-query(1279) {
      right: 32px;
    }

    @include mobile {
      position: static;
    }

    .dropdown_box {
      width: 146px;

      @include mobile {
        width: 100%;
      }
    }

    .btn_dropdown {
      padding: 13px 0 12px;
      border-bottom: 1px solid #333;
      font-family: NanumSquare;
      font-weight: 800;
      font-size: 14px;
      line-height: 16px;

      &:after {
        right: 4px;
        bottom: 17px;
        @include sp-desktop('ico_footer_dropdown');
      }

      &.on {
        &:after {
          @include sp-desktop('ico_footer_dropdown_on');
        }
      }
    }

    .dropdown_list {
      position: absolute;
      top: 0;
      bottom: auto;
      margin-top: 0;
      padding: 15px;
      background-color: #fff;
      @include vender-prefix(transform, translateY(-100%));

      .link {
        padding: 0;
        font-weight: 700;
        font-size: 13px;
        line-height: 23px;
      }

      .item {
        + .item {
          border-top: 0;
        }

        &:hover {
          background-color: #fff;

          .link {
            color: #333;
          }
        }
      }
    }
  }
}
