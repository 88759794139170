/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  .education_list & {
    margin: 0 -12px;
    max-height: 517px;

    @include tablet {
      margin: 0 -8px;
      max-height: 509px;
      padding-right: 32px;
      padding-left: 32px;
    }

    @include mobile {
      margin: 0 -24px;
      padding: 0 24px;
      max-height: 457px;
    }
  }

  .interview_list & {
    margin: 0 -14px;
    max-height: 434px;
    text-align: right;

    @include media-query(1279) {
      text-align: left;
    }

    @include tablet {
      margin: 0 -8px;
    }

    @include mobile {
      margin: 0 -24px;
      padding: 0 24px;
    }
  }

  .apply_slider & {
    margin: 0 -24px;
    padding: 0 24px;

    @include tablet {
      margin: 0 -12px;
      padding: 0 12px;
    }
  }

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin: 0 auto;

  .interview_list & {
    display: inline-block;
    margin: 0;
  }

  .apply_slider & {
    @include media-query(1279) {
      max-width: 100%;
      white-space: nowrap;
    }
  }

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  outline: none;

  .apply_slider & {
    width: 348px;
    height: 100%;
    margin: 0 24px;
    min-height: 194px;

    @include media-query(1279) {
      float: none;
      display: inline-block;
      width: calc(50% - 12px);
      margin: 0 12px;
      white-space: normal;
      vertical-align: top;
    }

    @include mobile {
      width: calc(100% - 60px);
      min-height: auto;
      margin: 0 16px;
    }

    + .slick-active {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }

    &[tabindex='-1'] {
      opacity: 0;

      @include mobile {
        opacity: 1;
      }
    }

    &[tabindex='0'] {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }

  [dir='rtl'] & {
    float: right;
  }
  img {
    display: block;
    max-width: 100%;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

// Default Variables
$slick-loader-path: './' !default;

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  cursor: pointer;
  color: transparent;
  top: 168px;
  line-height: 0px;
  font-size: 0px;

  @include tablet {
    top: 186px;
  }
}

.slick-prev {
  left: 0;
  @include sp-desktop('slick_arrow_left_active');

  &.slick-disabled {
    @include sp-desktop('slick_arrow_left_default');
  }

  &:hover {
    @include sp-desktop('slick_arrow_left_active');
  }
}

.slick-next {
  left: 60px;
  @include sp-desktop('slick_arrow_right_active');

  &.slick-disabled {
    @include sp-desktop('slick_arrow_right_default');
  }

  &:hover {
    @include sp-desktop('slick_arrow_right_active');
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Dots */

.slick-dots {
  display: none;
  margin: 17px 0 -7px;
  text-align: center;

  .interview_list & {
    margin-top: 12px;
  }

  @include mobile {
    display: block;
  }

  li {
    display: inline-block;

    button {
      border: 0;
      display: block;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      padding: 4px;
      &:hover,
      &:focus {
        outline: none;
      }
      &:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        font-size: 6px;
        text-align: center;
        background-color: #bfbfbf;
        @include border-radius(50%);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      background: #1ace5e;
    }
  }
}
