#header {
  height: 93px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #fff;

  @include media-query(1279) {
    position: fixed;
    top: 0;
    width: 100%;
    height: 71px;
    z-index: 50;
  }

  &.on {
    @include media-query(1279) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: auto;
      background-color: #fff;
      z-index: 100;

      .gnb {
        .gnb_list {
          display: block;
        }

        .btn_menu {
          display: none;
        }

        .btn_close {
          display: block;
        }
      }
    }
  }

  .inner {
    height: 100%;
    @include clear;
  }

  .logo {
    float: left;
    margin-top: 30px;

    @include media-query(1279) {
      margin-top: 18px;
    }

    a {
      display: block;
      padding: 10px 0;

      &:before {
        content: '';
        display: block;
        @include sp-desktop('img_logo');

        @include media-query(1279) {
          @include sp-mobile('img_logo');
        }
      }
    }
  }

  .gnb {
    float: right;
    margin-top: 28px;

    @include media-query(1279) {
      margin-top: 0;
    }

    .btn_menu,
    .btn_close {
      display: none;
      margin-right: -32px;
      padding: 27px 32px;

      @include mobile {
        margin-right: -24px;
        padding: 27px 24px;
      }

      &:before {
        content: '';
        display: block;
      }
    }

    .btn_menu {
      &:before {
        @include sp-mobile('ico_menu');
      }

      @include media-query(1279) {
        display: block;
      }
    }

    .btn_close {
      &:before {
        @include sp-mobile('ico_close');
      }
    }

    .gnb_list {
      @include clear;

      @include media-query(1279) {
        display: none;
        position: absolute;
        top: 72px;
        right: 0;
        left: 0;
        background-color: #fff;

        &:after {
          display: none;
        }
      }

      .item {
        float: left;

        @include media-query(1279) {
          float: none;
          padding: 0 32px;
        }

        &.on {
          position: relative;

          .link {
            color: #1ace5e;

            &:after {
              content: '';
              position: absolute;
              bottom: -5px;
              left: 20px;
              right: 20px;
              height: 3px;
              background-color: #1ace5e;

              @include media-query(1279) {
                display: none;
              }
            }
          }
        }

        &:last-child {
          .link {
            padding-right: 0;

            &:after {
              right: 0;
            }
          }
        }
      }

      .link {
        display: block;
        padding: 5px 20px;
        font-family: Calibre;
        font-weight: 600;
        font-size: 20px;

        @include media-query(1279) {
          padding: 20px 0;
          border-bottom: 1px solid #dedede;
          line-height: 20px;
        }
      }
    }
  }
}

.sub_header {
  background-color: #fff;

  @include media-query(1279) {
    position: fixed;
    top: 72px;
    width: 100%;
    z-index: 50;
    border-bottom: 1px solid #e9e9e9;
  }

  @include mobile {
    overflow-x: auto;
    white-space: nowrap;
  }

  .inner {
    @include mobile {
      padding: 0;
    }
  }

  .tab_list {
    font-size: 0;
  }

  .tab {
    display: inline-block;
    padding: 18px 0 17px;

    @include tablet {
      padding-bottom: 16px;
    }

    @include mobile {
      padding: 23px 0 20px;

      &:first-child {
        padding-left: 24px;

        a {
          padding-left: 0;
        }
      }

      &:last-child {
        padding-right: 24px;

        a {
          padding-right: 0;
        }
      }
    }

    a {
      display: block;
      padding: 0 24px;
      font-size: 16px;
      color: #333;
      line-height: 45px;
      letter-spacing: -0.5px;

      @include mobile {
        padding: 0 10px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.54px;
      }
    }

    &.on {
      a {
        position: relative;
        color: #fff;
        z-index: 0;

        @include mobile {
          color: #edb44a;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #edb44a;
          @include vender-prefix(border-radius, 30.5px);
          z-index: -1;

          @include mobile {
            display: none;
          }
        }
      }

      &:nth-child(1) {
        a {
          &:after {
            background-color: #79ced5;
          }

          @include mobile {
            color: #79ced5;
          }
        }
      }

      &:nth-child(3) {
        a {
          &:after {
            background-color: #6390f4;
          }

          @include mobile {
            color: #6390f4;
          }
        }
      }

      &:nth-child(4) {
        a {
          &:after {
            background-color: #56b982;
          }

          @include mobile {
            color: #56b982;
          }
        }
      }

      &:nth-child(5) {
        a {
          &:after {
            background-color: #6371ca;
          }

          @include mobile {
            color: #6371ca;
          }
        }
      }
    }
  }

  &.research {
    .tab {
      &.on {
        a {
          @include mobile {
            color: #6390f4;
          }

          &:after {
            background-color: #6390f4;
            @include vender-prefix(border-radius, 30.5px);
          }
        }

        &:nth-child(1) {
          a {
            &:after {
              background-color: #79ced5;
            }

            @include mobile {
              color: #79ced5;
            }
          }
        }

        &:nth-child(3) {
          a {
            &:after {
              background-color: #56b982;
            }

            @include mobile {
              color: #56b982;
            }
          }
        }

        &:nth-child(4) {
          a {
            &:after {
              background-color: #6371ca;
            }

            @include mobile {
              color: #6371ca;
            }
          }
        }
      }
    }
  }
}
