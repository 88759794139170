@use "sass:math";

$mobile-vars: (
	'ico_close': (
		offset-x: -774px,
		offset-y: -140px,
		width: 44px,
		height: 44px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_contest_01': (
		offset-x: 0px,
		offset-y: -312px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_contest_02': (
		offset-x: -124px,
		offset-y: -312px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_contest_03': (
		offset-x: -248px,
		offset-y: -312px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_contest_04': (
		offset-x: -482px,
		offset-y: 0px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_contest_05': (
		offset-x: -482px,
		offset-y: -124px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_menu': (
		offset-x: -782px,
		offset-y: -308px,
		width: 44px,
		height: 34px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_more': (
		offset-x: -606px,
		offset-y: -140px,
		width: 80px,
		height: 80px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_more_btn_1': (
		offset-x: -778px,
		offset-y: -96px,
		width: 40px,
		height: 40px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_more_btn_2': (
		offset-x: -690px,
		offset-y: -140px,
		width: 80px,
		height: 80px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_public_01': (
		offset-x: -482px,
		offset-y: -248px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_public_02': (
		offset-x: 0px,
		offset-y: -436px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_public_03': (
		offset-x: -124px,
		offset-y: -436px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_public_04': (
		offset-x: -248px,
		offset-y: -436px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_public_05': (
		offset-x: -372px,
		offset-y: -436px,
		width: 120px,
		height: 120px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_question': (
		offset-x: -606px,
		offset-y: -346px,
		width: 40px,
		height: 40px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_research_performance_boostcamp': (
		offset-x: 0px,
		offset-y: -104px,
		width: 248px,
		height: 100px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_research_performance_boostcourse': (
		offset-x: 0px,
		offset-y: 0px,
		width: 252px,
		height: 100px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_research_performance_edwith': (
		offset-x: 0px,
		offset-y: -208px,
		width: 170px,
		height: 100px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_research_performance_entry': (
		offset-x: -256px,
		offset-y: 0px,
		width: 222px,
		height: 100px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_research_performance_sw': (
		offset-x: -256px,
		offset-y: -104px,
		width: 210px,
		height: 100px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_return_list': (
		offset-x: -774px,
		offset-y: -188px,
		width: 30px,
		height: 30px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'ico_share': (
		offset-x: -606px,
		offset-y: -224px,
		width: 80px,
		height: 80px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'img_ci_boostcamp': (
		offset-x: -606px,
		offset-y: 0px,
		width: 232px,
		height: 44px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'img_ci_boostcourse': (
		offset-x: -606px,
		offset-y: -48px,
		width: 230px,
		height: 44px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'img_ci_edwith': (
		offset-x: -690px,
		offset-y: -268px,
		width: 140px,
		height: 34px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'img_ci_entry': (
		offset-x: -606px,
		offset-y: -308px,
		width: 172px,
		height: 34px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'img_ci_jr_school': (
		offset-x: -606px,
		offset-y: -96px,
		width: 168px,
		height: 40px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'img_ci_jrnaver': (
		offset-x: -690px,
		offset-y: -224px,
		width: 141px,
		height: 40px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'img_ci_sw': (
		offset-x: -174px,
		offset-y: -208px,
		width: 188px,
		height: 88px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
	'img_logo': (
		offset-x: 0px,
		offset-y: -560px,
		width: 394px,
		height: 32px,
		total-width: 838px,
		total-height: 592px,
		imageSrc: '../img/mobile.png'
	),
);
$mobile-origin: (
  total-width: 838px,
  total-height: 592px,
  imageSrc: '../img/mobile.png'
);

@mixin sprite-mobile-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-mobile-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-mobile-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-mobile {
  @include sprite-mobile-size($mobile-origin);
  @include sprite-mobile-image($mobile-origin);
}

@mixin sp-mobile($image, $size: true) {
  @include set-mobile;
	background-position: math.div(map-get(map-get($mobile-vars, $image), 'offset-x'), 2)
	math.div(map-get(map-get($mobile-vars, $image), 'offset-y'), 2);
	@if $size {
		width: math.div(map-get(map-get($mobile-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($mobile-vars, $image), 'height'), 2);
	}
}
