@use "sass:math";

$desktop-vars: (
	'ico_arrow': (
		offset-x: -799px,
		offset-y: -524px,
		width: 36px,
		height: 36px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_contest_01': (
		offset-x: -602px,
		offset-y: -236px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_contest_02': (
		offset-x: -602px,
		offset-y: -380px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_contest_03': (
		offset-x: 0px,
		offset-y: -644px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_contest_04': (
		offset-x: -144px,
		offset-y: -644px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_contest_05': (
		offset-x: -288px,
		offset-y: -644px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_download': (
		offset-x: -800px,
		offset-y: -118px,
		width: 42px,
		height: 42px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_dropdown': (
		offset-x: -930px,
		offset-y: -738px,
		width: 36px,
		height: 22px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_dropdown_on': (
		offset-x: -800px,
		offset-y: -208px,
		width: 36px,
		height: 22px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_footer_dropdown': (
		offset-x: -1074px,
		offset-y: -112px,
		width: 16px,
		height: 8px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_footer_dropdown_on': (
		offset-x: -1074px,
		offset-y: -124px,
		width: 16px,
		height: 8px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_link_blog': (
		offset-x: -422px,
		offset-y: -852px,
		width: 60px,
		height: 32px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_link_blog_hover': (
		offset-x: -486px,
		offset-y: -852px,
		width: 60px,
		height: 32px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_link_home': (
		offset-x: -800px,
		offset-y: -164px,
		width: 40px,
		height: 40px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_link_home_hover': (
		offset-x: 0px,
		offset-y: -890px,
		width: 40px,
		height: 40px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_link_post': (
		offset-x: -44px,
		offset-y: -890px,
		width: 40px,
		height: 40px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_link_post_hover': (
		offset-x: -88px,
		offset-y: -890px,
		width: 40px,
		height: 40px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_list_next': (
		offset-x: -990px,
		offset-y: -112px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_list_next_hover': (
		offset-x: -990px,
		offset-y: -196px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_list_prev': (
		offset-x: -990px,
		offset-y: -280px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_list_prev_hover': (
		offset-x: -990px,
		offset-y: -364px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_more': (
		offset-x: -846px,
		offset-y: -568px,
		width: 108px,
		height: 108px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_more_btn_1': (
		offset-x: -930px,
		offset-y: -680px,
		width: 54px,
		height: 54px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_more_btn_2': (
		offset-x: -720px,
		offset-y: -644px,
		width: 108px,
		height: 108px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_more_hover': (
		offset-x: -990px,
		offset-y: 0px,
		width: 108px,
		height: 108px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_play': (
		offset-x: -846px,
		offset-y: -432px,
		width: 132px,
		height: 132px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_popup_close': (
		offset-x: -746px,
		offset-y: -380px,
		width: 64px,
		height: 64px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_public_01': (
		offset-x: -432px,
		offset-y: -644px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_public_02': (
		offset-x: -576px,
		offset-y: -644px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_public_03': (
		offset-x: -846px,
		offset-y: 0px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_public_04': (
		offset-x: -846px,
		offset-y: -144px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_public_05': (
		offset-x: -846px,
		offset-y: -288px,
		width: 140px,
		height: 140px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_question': (
		offset-x: -550px,
		offset-y: -322px,
		width: 48px,
		height: 48px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_research_performance_boostcamp': (
		offset-x: -316px,
		offset-y: 0px,
		width: 282px,
		height: 114px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_research_performance_boostcourse': (
		offset-x: 0px,
		offset-y: -120px,
		width: 288px,
		height: 114px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_research_performance_edwith': (
		offset-x: -602px,
		offset-y: -118px,
		width: 194px,
		height: 114px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_research_performance_entry': (
		offset-x: -316px,
		offset-y: -118px,
		width: 252px,
		height: 114px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_research_performance_sw': (
		offset-x: -602px,
		offset-y: 0px,
		width: 240px,
		height: 114px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_return_list': (
		offset-x: -990px,
		offset-y: -448px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_return_list_hover': (
		offset-x: -990px,
		offset-y: -532px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_sns_band': (
		offset-x: -990px,
		offset-y: -616px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_sns_blog': (
		offset-x: -990px,
		offset-y: -700px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_sns_facebook': (
		offset-x: -990px,
		offset-y: -784px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_sns_twitter': (
		offset-x: -846px,
		offset-y: -680px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'ico_url': (
		offset-x: -746px,
		offset-y: -236px,
		width: 80px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_boostcamp': (
		offset-x: 0px,
		offset-y: -322px,
		width: 386px,
		height: 72px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_boostcamp_02': (
		offset-x: -602px,
		offset-y: -524px,
		width: 193px,
		height: 36px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_boostcourse': (
		offset-x: 0px,
		offset-y: -398px,
		width: 386px,
		height: 72px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_boostcourse_02': (
		offset-x: -390px,
		offset-y: -398px,
		width: 192px,
		height: 36px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_ediwth_02': (
		offset-x: -810px,
		offset-y: -788px,
		width: 116px,
		height: 29px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_edwith': (
		offset-x: -574px,
		offset-y: -788px,
		width: 232px,
		height: 58px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_entry': (
		offset-x: -284px,
		offset-y: -788px,
		width: 286px,
		height: 58px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_entry_02': (
		offset-x: -390px,
		offset-y: -438px,
		width: 144px,
		height: 29px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_jr': (
		offset-x: 0px,
		offset-y: -574px,
		width: 362px,
		height: 66px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_jr_school': (
		offset-x: -366px,
		offset-y: -574px,
		width: 280px,
		height: 60px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_jr_tv': (
		offset-x: 0px,
		offset-y: -788px,
		width: 280px,
		height: 60px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_jrnaver': (
		offset-x: -356px,
		offset-y: -238px,
		width: 226px,
		height: 64px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_khan': (
		offset-x: 0px,
		offset-y: -238px,
		width: 352px,
		height: 80px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_sef': (
		offset-x: -296px,
		offset-y: -474px,
		width: 278px,
		height: 66px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_sw': (
		offset-x: 0px,
		offset-y: 0px,
		width: 312px,
		height: 116px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_ci_sw_02': (
		offset-x: -390px,
		offset-y: -322px,
		width: 156px,
		height: 59px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_logo': (
		offset-x: 0px,
		offset-y: -852px,
		width: 418px,
		height: 34px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'img_news_logo': (
		offset-x: 0px,
		offset-y: -474px,
		width: 292px,
		height: 96px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'slick_arrow_left_active': (
		offset-x: -746px,
		offset-y: -320px,
		width: 80px,
		height: 48px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'slick_arrow_left_default': (
		offset-x: -746px,
		offset-y: -448px,
		width: 80px,
		height: 48px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'slick_arrow_right_active': (
		offset-x: -650px,
		offset-y: -574px,
		width: 80px,
		height: 48px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
	'slick_arrow_right_default': (
		offset-x: -734px,
		offset-y: -574px,
		width: 80px,
		height: 48px,
		total-width: 1098px,
		total-height: 930px,
		imageSrc: '../img/desktop.png'
	),
);
$desktop-origin: (
  total-width: 1098px,
  total-height: 930px,
  imageSrc: '../img/desktop.png'
);

@mixin sprite-desktop-size($image) {
	background-size: math.div(map-get($image, 'total-width'), 2) math.div(map-get($image, 'total-height'), 2);
}

@mixin sprite-desktop-image($image) {
	$image-path: map-get($image, 'imageSrc');
	background-image: url($image-path);
}

@mixin sprite-desktop-image-retina($image) {
	$image-path: map-get($image, 'imageSrc');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';
	background-image: url($image-path-retina);
}

@mixin set-desktop {
  @include sprite-desktop-size($desktop-origin);
  @include sprite-desktop-image($desktop-origin);
}

@mixin sp-desktop($image, $size: true) {
  @include set-desktop;
	background-position: math.div(map-get(map-get($desktop-vars, $image), 'offset-x'), 2)
	math.div(map-get(map-get($desktop-vars, $image), 'offset-y'), 2);
	@if $size {
		width: math.div(map-get(map-get($desktop-vars, $image), 'width'), 2);
		height: math.div(map-get(map-get($desktop-vars, $image), 'height'), 2);
	}
}
