// webfont NanumSquare
@font-face {
	font-family: 'NanumSquare';
	src: url("../font/NanumSquare/NanumSquareR.eot");
	src: url("../font/NanumSquare/NanumSquareR.eot?#iefix") format('embedded-opentype'), url("../font/NanumSquare/NanumSquareR.woff") format('woff'), url("../font/NanumSquare/NanumSquareR.ttf") format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: 'NanumSquare';
	src: url("../font/NanumSquare/NanumSquareB.eot");
	src: url("../font/NanumSquare/NanumSquareB.eot?#iefix") format('embedded-opentype'), url("../font/NanumSquare/NanumSquareB.woff") format('woff'), url("../font/NanumSquare/NanumSquareB.ttf") format('truetype');
	font-weight: 700;
}
@font-face {
	font-family: 'NanumSquare';
	src: url("../font/NanumSquare/NanumSquareEB.eot");
	src: url("../font/NanumSquare/NanumSquareEB.eot?#iefix") format('embedded-opentype'), url("../font/NanumSquare/NanumSquareEB.woff") format('woff'), url("../font/NanumSquare/NanumSquareEB.ttf") format('truetype');
	font-weight: 800;
}
@font-face {
	font-family: 'NanumSquare';
	src: url("../font/NanumSquare/NanumSquareL.eot");
	src: url("../font/NanumSquare/NanumSquareL.eot?#iefix") format('embedded-opentype'), url("../font/NanumSquare/NanumSquareL.woff") format('woff'), url("../font/NanumSquare/NanumSquareL.ttf") format('truetype');
	font-weight: 300;
}

// webfont Calibre
@font-face {
	font-family: 'Calibre';
	src: url("../font/Calibre/Calibre-Regular.eot");
	src: url("../font/Calibre/Calibre-Regular.eot?#iefix") format('embedded-opentype'), url("../font/Calibre//Calibre-Regular.woff") format('woff'), url("../font/Calibre/Calibre-Regular.ttf") format('truetype');
	font-weight: normal;
}
@font-face {
	font-family: 'Calibre';
	src: url("../font/Calibre/Calibre-Medium.eot");
	src: url("../font/Calibre/Calibre-Medium.eot?#iefix") format('embedded-opentype'), url("../font/Calibre/Calibre-Medium.woff") format('woff'), url("../font/Calibre/Calibre-Medium.ttf") format('truetype');
	font-weight: 500;
}
@font-face {
	font-family: 'Calibre';
	src: url("../font/Calibre/Calibre-Semibold.eot");
	src: url("../font/Calibre/Calibre-Semibold.eot?#iefix") format('embedded-opentype'), url("../font/Calibre/Calibre-Semibold.woff") format('woff'), url("../font/Calibre/Calibre-Semibold.ttf") format('truetype');
	font-weight: 600;
}
@font-face {
	font-family: 'Calibre';
	src: url("../font/Calibre/Calibre-Bold.eot");
	src: url("../font/Calibre/Calibre-Bold.eot?#iefix") format('embedded-opentype'), url("../font/Calibre/Calibre-Bold.woff") format('woff'), url("../font/Calibre/Calibre-Bold.ttf") format('truetype');
	font-weight: 700;
}
@font-face {
	font-family: 'Calibre';
	src: url("../font/Calibre/Calibre-Light.eot");
	src: url("../font/Calibre/Calibre-Light.eot?#iefix") format('embedded-opentype'), url("../font/Calibre/Calibre-Light.woff") format('woff'), url("../font/Calibre/Calibre-Light.ttf") format('truetype');
	font-weight: 300;
}