.wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 1240px;
  min-height: calc(100vh - 72px);
  -webkit-flex-direction: column;
  flex-direction: column;

  @include media-query(1279) {
    min-width: 320px;
    padding-top: 72px;
  }
}

.content {
  min-height: calc(100vh - 285px);

  @include mobile {
    min-height: calc(100vh - 325px);
  }
}

.inner {
  position: relative;
  width: 1160px;
  margin: 0 auto;

  @include media-query(1279) {
    width: 100%;
    @include box-sizing;
  }

  @include tablet {
    padding: 0 32px;
  }

  @include mobile {
    padding: 0 24px;
  }
}

.section_article {
  padding: 120px 0;

  &.jr_school {
    border-bottom: 1px solid #dedede;
    background-color: #f8fafc;
  }

  @include tablet {
    padding: 54px 0 80px;
  }

  @include mobile {
    padding: 37px 0 60px;
  }

  &.type_bg {
    padding-bottom: 119px;
    border-bottom: 1px solid #dedede;
    background-color: #f8fafc;

    @include tablet {
      padding-bottom: 88px;
    }

    @include mobile {
      padding-bottom: 60px;
    }
  }

  &.khan_academy {
    background: #f8fafc url(../img/pc/img_khan_bg.png) calc(50% + 399px) 194px no-repeat;
    @include vender-prefix(background-size, 579px 418px);

    @include tablet {
      background-position: right -107px top 164px;
    }

    @include mobile {
      background: #f8fafc url(../img/mo/img_khan_bg.png) right -22px top 88px no-repeat;
      @include vender-prefix(background-size, 321px 233px);
    }
  }

  @at-root .list_top {
    position: relative;
    margin-bottom: 48px;

    @include tablet {
      margin-bottom: 32px;
    }

    @include mobile {
      margin-bottom: 24px;
    }

    .list_title {
      font-family: Calibre;
      font-size: 38px;
      font-weight: 700;
      line-height: 38px;

      @include tablet {
        font-size: 36px;
      }

      @include mobile {
        font-size: 30px;
        line-height: 31px;
      }
    }

    .btn_list {
      position: absolute;
      top: -9px;
      right: -2px;
      font-size: 0;

      .btn_item {
        display: inline-block;
      }

      @include mobile {
        top: -10px;
        right: 4px;

        .btn_item {
          display: inline-block;

          + .btn_item {
            padding-left: 21px;
          }
        }
      }
    }
  }
}

.about {
  .visual_area {
    height: 800px;
    background: url(../img/pc/bg_about.jpg) no-repeat 50% 50%;
    @include vender-prefix(background-size, cover);

    @include tablet {
      height: 660px;
    }

    @include mobile {
      height: 580px;
    }

    .inner {
      height: 100%;
    }

    .main_title {
      position: absolute;
      top: 260px;
      left: -2px;
      width: 373px;
      height: 296px;
      background: url(../img/pc/img_about_main_txt.png) no-repeat;
      @include vender-prefix(background-size, 373px 296px);

      @include tablet {
        top: 180px;
        left: 32px;
      }

      @include mobile {
        top: 200px;
        left: 24px;
        width: 224px;
        height: 178px;
        @include vender-prefix(background-size, 224px 178px);
      }
    }
  }

  .section_title {
    font-size: 36px;
    text-align: center;
    line-height: 52px;
    letter-spacing: -1px;

    @include media-query(1279) {
      letter-spacing: -0.5px;
    }

    @include tablet {
      font-size: 30px;
      line-height: 44px;
    }

    @include mobile {
      font-size: 24px;
      line-height: 34px;
    }

    @include media-query(390) {
      text-align: left;
    }
  }

  .section_txt {
    margin-top: 40px;
    font-size: 18px;
    color: #848484;
    text-align: center;
    line-height: 32px;
    letter-spacing: -0.5px;

    @include media-query(1279) {
      letter-spacing: 0;
    }

    @include tablet {
      margin-top: 28px;
      font-size: 16px;
      line-height: 28px;
    }

    @include mobile {
      margin-top: 24px;
      font-size: 14px;
      line-height: 24px;
      word-break: keep-all;
    }

    @include media-query(390) {
      text-align: left;
    }

    @include media-query(374) {
      .m_br {
        display: none;
      }
    }

    .point_txt {
      color: #000;
    }
  }

  .section_info {
    padding: 130px 0 112px;

    @include tablet {
      padding: 100px 0;
    }

    @include mobile {
      padding: 60px 0;
    }

    .section_title {
      font-family: Calibre;
      font-weight: 700;
      font-size: 28px;
      color: #1ace5e;
      line-height: 28px;
      letter-spacing: 0;

      @include tablet {
        font-size: 22px;
        line-height: 22px;
      }

      @include mobile {
        font-size: 16px;
        line-height: 16px;
      }
    }

    .section_txt {
      margin-top: 20px;
      font-size: 36px;
      line-height: 52px;
      letter-spacing: -1px;
      color: #000;

      @include media-query(1279) {
        letter-spacing: -0.5px;
      }

      @include tablet {
        margin-top: 22px;
        font-size: 30px;
        line-height: 44px;
      }

      @include mobile {
        margin-top: 25px;
        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  .section_vision {
    padding: 120px 0;
    background: #f7fafd url(../img/pc/bg_about_graphic_01.png) no-repeat 50% 0;
    @include vender-prefix(background-size, 2200px 871px);

    @include tablet {
      padding: 100px 0;
    }

    @include mobile {
      padding: 60px 0;
    }

    .section_txt {
      &:after {
        content: '';
        display: block;
        height: 310px;
        margin: 48px auto 0;
        background: url('../img/pc/img_vision_graphic.png') no-repeat 50% 50%;
        @include vender-prefix(background-size, 704px 310px);

        @include tablet {
          margin-top: 39px;
          height: 279px;
          background-image: url('../img/tablet/img_vision_graphic.png');
          @include vender-prefix(background-size, 603px 279px);
        }

        @include mobile {
          height: 155px;
          margin: 27px -24px 0;
          background-image: url('../img/mo/img_vision_graphic.png');
          @include vender-prefix(background-size, 314px 155px);
        }
      }
    }
  }

  .section_history {
    padding: 120px 0 140px;
    background: #141418 url('../img/pc/bg_about_graphic_02.png') no-repeat 50% 0;
    @include vender-prefix(background-size, 2200px 772px);

    @include tablet {
      padding: 100px 0;
      background: #141418 url('../img/tablet/bg_about_graphic_02.png') no-repeat 50%;
      @include vender-prefix(background-size, 2200px 754px);
    }

    @include mobile {
      padding: 60px 0;
      background-position: 50% bottom;
    }

    .section_title {
      color: #fff;
    }

    .box_content {
      margin: 60px -20px 0;
      font-size: 0;
      text-align: center;

      @include tablet {
        margin: 48px -24px 0;
      }

      @include mobile {
        margin: 32px 0 0;
      }

      .info_box {
        display: inline-block;
        width: 460px;
        height: 296px;
        margin: 0 20px;
        padding: 60px 0;
        background-color: #21232d;
        text-align: center;
        @include box-sizing;
        @include border-radius(8px);
        @include vender-prefix(box-shadow, 0 2px 24px 0 rgba(0, 0, 0, 0.3));

        @include tablet {
          width: 338px;
          height: 272px;
          margin: 0 14px;
          padding: 52px 0;
        }

        @include mobile {
          display: block;
          width: 327px;
          height: 216px;
          margin: 0 auto;
          padding: 40px 0 38px;
        }

        @include media-query(390) {
          width: 100%;
        }

        & + .info_box {
          @include mobile {
            margin-top: 16px;
          }
        }

        .info_title {
          font-size: 24px;
          color: #1ace5e;

          @include tablet {
            font-size: 20px;
            line-height: 22px;
          }

          @include mobile {
            font-size: 18px;
            line-height: 20px;
          }
        }

        .info_txt {
          margin-top: 18px;
          font-size: 16px;
          line-height: 25px;
          color: #848484;

          @include tablet {
            margin-top: 20px;
            font-size: 15px;
            line-height: 23px;
          }

          @include mobile {
            margin-top: 12px;
            font-size: 14px;
            line-height: 22px;
          }
        }

        .bd_btn_link {
          margin-top: 24px;

          @include mobile {
            margin-top: 16px;
          }
        }
      }
    }
  }

  .section_mission {
    padding: 123px 0 116px;

    @include tablet {
      padding: 100px 0;
    }

    @include mobile {
      padding: 60px 0;
    }

    .section_top {
      padding-right: 250px;
      text-align: left;

      @include media-query(1279) {
        padding-right: 0;
      }

      .btn_link {
        position: absolute;
        top: 53px;
        right: 0;

        @include media-query(1279) {
          position: static;
          margin-top: 20px;
        }
      }
    }

    .section_title {
      font-weight: 700;
      font-family: Calibre;
      font-size: 44px;
      text-align: left;
      line-height: 44px;
      letter-spacing: 0;

      @include tablet {
        font-size: 38px;
        line-height: 38px;
      }

      @include mobile {
        font-size: 32px;
        line-height: 32px;
      }
    }

    .section_txt {
      margin-top: 14px;
      font-size: 16px;
      text-align: left;
      line-height: 25px;
      letter-spacing: 0;

      @include media-query(1279) {
        margin-top: 12px;
      }

      @include tablet {
        font-size: 16px;
        line-height: 28px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 24px;
      }
    }

    @at-root .education_list {
      margin-top: 61px;
      @include clear;

      @include media-query(1279) {
        margin: 40px -32px 0;
        overflow: hidden;
      }

      @include mobile {
        margin: 40px 0 0;
        overflow: visible;
      }

      .item {
        position: relative;
        overflow: hidden;
        float: left;
        width: 272px;
        height: 497px;
        margin: 0 12px;
        padding: 188px 24px 70px;
        @include border-radius(8px);
        @include box-sizing;

        @include tablet {
          height: 501px;
          margin: 0 8px;
          padding: 16.4% 24px 89px;
        }

        @media screen and (min-width: 800px) and (max-width: 1279px) {
          padding: 15.4% 24px 89px;
        }

        @include mobile {
          width: 287px;
          height: 457px;
          margin: 0 10px;
          padding: 188px 20px 85px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 272px;
          height: 160px;
          background-repeat: no-repeat;
          background-position: 50%;
          @include vender-prefix(background-size, 272px 160px);

          @include tablet {
            width: auto;
            height: 16.668vw;
            @include vender-prefix(background-size, cover);
          }

          @include mobile {
            width: 287px;
            height: 160px;
            @include vender-prefix(background-size, contain);
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border: 1px solid #e6e6e6;
          @include border-radius(8px);
          z-index: -1;
        }

        &:nth-child(1) {
          &:before {
            background-image: url(../img/pc/img_mission_04.jpg);
          }
        }

        &:nth-child(2) {
          &:before {
            background-image: url(../img/pc/img_mission_01.jpg);
          }
        }

        &:nth-child(3) {
          &:before {
            background-image: url(../img/pc/img_mission_02.jpg);
          }
        }

        &:nth-child(4) {
          &:before {
            background-image: url(../img/pc/img_mission_03.jpg);
          }
        }
      }

      .edu_title {
        font-size: 15px;
        line-height: 24px;
        color: #848484;
      }

      .edu_txt {
        margin-top: 8px;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.5px;
        word-break: keep-all;

        @include mobile {
          margin-top: 11px;
        }
      }

      .edu_desc {
        margin-top: 25px;
        font-size: 15px;
        line-height: 24px;
        color: #848484;
        word-break: keep-all;

        @include media-query(1279) {
          margin-top: 27px;
        }

        @include tablet2 {
          br {
            display: none;
          }
        }

        @include mobile {
          margin-top: 24px;
          font-size: 14px;

          br {
            display: none;
          }
        }
      }

      .service_group {
        position: absolute;
        bottom: 44px;

        @include tablet {
          bottom: 32px;
        }

        @include mobile {
          bottom: 34px;
        }

        .service {
          font-size: 15px;
          color: #1ace5e;

          + .service {
            margin-left: 18px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.wrap[class*='service'] {
  @include tablet {
    padding-top: 152px;
  }

  @include mobile {
    padding-top: 140px;
  }

  .visual_area {
    background-color: #f8f7f0;

    .inner {
      height: 320px;
      background: url(../img/pc/bg_service_01.png) no-repeat 50% 50%;
      @include vender-prefix(background-size, 1160px 320px);

      @include mobile {
        height: 280px;
        background-image: url(../img/mo/bg_service_01.png);
        @include vender-prefix(background-size, 767px 280px);
      }
    }

    .main_title {
      padding-top: 126px;
      font-size: 26px;
      line-height: 36px;

      @include tablet {
        font-size: 24px;
        line-height: 34px;
      }

      @include mobile {
        padding-top: 80px;
        font-size: 20px;
        line-height: 30px;
      }

      .m_br {
        @include mobile {
          display: block;
        }
      }
    }
  }

  @at-root .service_info {
    position: relative;

    .khan_academy & {
      @include media-query(1279) {
        overflow: hidden;
      }

      @include tablet {
        margin: 0 -32px;
        padding: 0 32px;
      }

      @include mobile {
        margin: 0 -24px;
        padding: 0 24px;
      }
    }

    .con_left {
      position: absolute;
      top: 0;
      left: 0;
      width: 396px;

      @include media-query(1279) {
        position: relative;
        width: auto;
      }
    }

    .con_right {
      padding-left: 396px;

      @include media-query(1279) {
        padding-left: 0;
      }
    }

    .service_name {
      position: relative;
      font-size: 24px;
      line-height: 26px;

      @include media-query(1279) {
        display: block;
        margin-bottom: 24px;
        line-height: 44px;
      }

      @include tablet {
        padding-right: 200px;
      }

      @include mobile {
        margin-bottom: 16px;
        padding: 2px 0;
        font-size: 22px;
        padding-right: 100px;
      }

      &:after {
        content: '';
        display: block;
        margin-top: 20px;

        .entry & {
          @include sp-desktop('img_ci_entry');
        }

        .sw & {
          @include sp-desktop('img_ci_sw');
        }

        .boostcamp & {
          @include sp-desktop('img_ci_boostcamp');
        }

        .boostcourse & {
          @include sp-desktop('img_ci_boostcourse');
        }

        .edwith & {
          @include sp-desktop('img_ci_edwith');
        }

        .khan_academy & {
          @include sp-desktop('img_ci_khan');
        }

        .sef & {
          @include sp-desktop('img_ci_sef');
        }

        .jr_tv & {
          @include sp-desktop('img_ci_jr_tv');
        }

        .jr_school & {
          @include sp-desktop('img_ci_jr_school');
        }

        @include media-query(1279) {
          display: none;
        }
      }

      .full_name {
        @include mobile {
          display: none;
        }
      }
    }

    .link_list {
      margin-top: 30px;
      @include clear;

      @include media-query(1279) {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 0;
      }

      .item {
        float: left;
        width: 44px;
        height: 44px;
        border: 1px solid #dedede;
        @include border-radius(50%);
        @include box-sizing;

        + .item {
          margin-left: 5px;

          @include media-query(1279) {
            margin-left: 8px;
          }
        }
      }

      .outlink {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          @include vender-prefix(transform, translate(-50%, -50%));
        }

        &.ico_home {
          &:before {
            @include sp-desktop('ico_link_home');
          }

          @include hover {
            &:before {
              @include sp-desktop('ico_link_home_hover');
            }
          }
        }

        &.ico_post {
          &:before {
            @include sp-desktop('ico_link_post');
          }

          @include hover {
            &:before {
              @include sp-desktop('ico_link_post_hover');
            }
          }
        }

        &.ico_blog {
          &:before {
            @include sp-desktop('ico_link_blog');
          }

          @include hover {
            &:before {
              @include sp-desktop('ico_link_blog_hover');
            }
          }
        }
      }
    }

    .thum_group {
      @include clear;
      margin: 0 -14px;

      @include mobile {
        margin: 0 -8px;
      }

      .thum_box {
        float: left;
        width: 368px;
        margin: 0 14px;

        @include tablet {
          width: calc(50% - 28px);
        }

        @include mobile {
          float: left;
          width: calc(50% - 16px);
          margin: 0 8px;
        }
      }
    }

    .thum_box {
      img {
        width: 100%;
        height: 100%;
      }

      [class^='thum'] {
        display: block;
      }

      .caption {
        display: block;
        margin-top: 12px;
        font-size: 15px;
        line-height: 24px;
        color: #333;

        @include tablet {
          margin-top: 7px;
        }

        @include mobile {
          margin-top: 4px;
        }
      }
    }

    .info_title {
      display: block;
      margin-top: 28px;
      font-size: 16px;
      line-height: 28px;
      color: #333;

      @include mobile {
        font-size: 14px;
        line-height: 19px;
      }

      + .info_txt {
        margin-top: 0;
      }
    }

    .info_txt {
      margin-top: 48px;
      font-size: 16px;
      line-height: 28px;
      color: #848484;
      word-break: keep-all;

      @include mobile {
        margin-top: 37px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    @at-root .data_board {
      margin-top: 60px;

      @include tablet {
        margin-top: 47px;
      }

      @include mobile {
        margin-top: 0;
      }

      .data_board_box {
        display: flex;
        margin-top: 78px;

        @include mobile {
          flex-direction: column;
          margin-top: 50px;
        }

        &:first-child {
          margin-top: 0;

          @include mobile {
            margin-top: 34px;
          }
        }

        .data_list_title {
          width: 132px;
          margin-top: -2px;
          font-size: 18px;

          @include mobile {
            width: auto;
            font-size: 16px;
          }
        }

        .data_list {
          @include mobile {
            display: block;
            margin-top: -5px;
          }

          .item {
            width: 184px;
            padding-top: 13px;
            box-sizing: border-box;

            @include tablet {
              width: 165px;
            }

            @include mobile {
              float: left;
              width: calc(50% - 20px);
              margin-top: 32px;
              padding-top: 13px;
            }
          }
        }
      }

      .data_list {
        @include clear;

        @include mobile {
          margin: 0 -10px;
        }

        &.view_list {
          @include mobile {
            margin: 0;
          }

          .item {
            @include mobile {
              &:nth-child(1) {
                float: none;
                margin: 36px 0 0;
              }
            }

            &.uv,
            &.pv {
              @include mobile {
                margin: 36px 0;
              }
            }

            &.uv {
              @include mobile {
                width: 53%;
              }
            }

            &.pv {
              @include mobile {
                width: 47%;
                white-space: nowrap;
              }

              .data_title {
                @include mobile {
                  display: none;
                }
              }
            }
          }
        }

        .item {
          position: relative;
          float: left;
          width: 228px;
          padding-top: 22px;
          border-top: 1px solid #dedede;

          @include tablet {
            width: 216px;
          }

          @include mobile {
            width: calc(50% - 20px);
            margin: 35px 10px 0;
            padding-top: 19px;
          }

          .edwith & {
            &:nth-child(odd) {
              &:last-child {
                @include mobile {
                  width: calc(100% - 20px);
                }
              }
            }

            &:last-child {
              .data {
                @include tablet {
                  font-size: 16px;
                }
              }
            }
          }

          + .item {
            margin-left: 40px;

            @include tablet {
              margin-left: 28px;
            }

            @include mobile {
              margin-left: 10px;
            }
          }
        }
      }

      .data_sub_title {
        position: absolute;
        top: 6px;
        font-family: Calibre;
        font-weight: 600;
        font-size: 20px;
        color: #2fb461;
        line-height: 20px;

        @include media-query(360) {
          font-size: 15px;
        }

        + .data {
          .data_num {
            @include mobile {
              padding-top: 2px;
              font-size: 44px;
              letter-spacing: -0.92px;
            }

            @include media-query(360) {
              font-size: 33px;
              line-height: 30px;
            }
          }
        }
      }

      .data {
        display: inline-block;
        font-size: 20px;
        vertical-align: bottom;
        color: #2fb461;

        @include mobile {
          font-size: 16px;
        }

        + .data {
          margin-left: 15px;
        }
      }

      .data_num {
        display: inline-block;
        font-family: Calibre;
        font-weight: 600;
        font-size: 56px;
        line-height: 53px;
        letter-spacing: -0.5px;
        vertical-align: sub;

        @include mobile {
          font-size: 48px;
          line-height: 45px;
        }

        @include media-query(360) {
          font-size: 33px;
          line-height: 30px;
        }
      }

      .data_title {
        display: block;
        margin-top: 15px;
        padding-left: 2px;
        font-size: 15px;
        color: #333;
        line-height: 17px;
        letter-spacing: -0.5px;

        @include mobile {
          margin-top: 12px;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.46px;
        }
      }
    }
  }

  @at-root .interview_area {
    margin-top: 80px;

    @include tablet {
      margin-top: 60px;
    }

    @include mobile {
      margin-top: 48px;
    }

    .interview_list {
      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background-color: #fff;
      }

      ::-webkit-scrollbar-thumb {
        background: #e9e9e9;
        @include border-radius(2.5px);
      }

      ::-webkit-scrollbar-button {
        display: none;
      }

      .item {
        width: 368px;
        height: 434px;
        margin: 0 14px;
        padding: 44px 12px;
        border: 0.5px solid #dedede;
        background-color: #fff;
        text-align: center;
        vertical-align: top;
        @include border-radius(8px);
        @include box-sizing;

        @include media-query(1279) {
          padding: 44px 24px;
        }

        @include tablet {
          width: 224px;
          margin: 0 8px;
        }

        @include mobile {
          width: 287px;
          margin: 0 10px;
        }
      }

      img {
        display: inline-block;
      }
    }

    .interview_type {
      display: block;
      margin-top: 20px;
      font-size: 16px;
      letter-spacing: -0.5px;

      &.student {
        color: #edb44a;
      }

      &.parent {
        color: #6371ca;
      }

      &.manager {
        color: #56b982;
      }
    }

    .interview_txt {
      overflow-y: auto;
      min-height: 120px;
      max-height: 120px;
      margin-top: 28px;
      padding: 0 20px;
      font-size: 15px;
      color: #848484;
      line-height: 24px;
      word-break: keep-all;

      @include media-query(1279) {
        padding: 0;
      }

      @include mobile {
        font-size: 14px;
      }
    }

    .interviewee {
      display: block;
      margin-top: 28px;
      font-size: 15px;
      color: #333;
      line-height: 24px;
    }
  }

  &.service_adult {
    .visual_area {
      background-color: #f0f3f8;

      .inner {
        background-image: url(../img/pc/bg_service_02.png);

        @include mobile {
          background-image: url(../img/mo/bg_service_02.png);
        }
      }
    }
  }

  &.service_mooc {
    .visual_area {
      background-color: #f0f8f8;

      .inner {
        background-image: url(../img/pc/bg_service_03.png);

        @include mobile {
          background-image: url(../img/mo/bg_service_03.png);
        }
      }

      .main_title {
        padding-top: 108px;

        @include mobile {
          padding-top: 65px;
        }
      }
    }
  }

  &.service_sef {
    .visual_area {
      background-color: #f0f2fd;

      .inner {
        background-image: url(../img/pc/bg_service_04.png);

        @include mobile {
          background-image: url(../img/mo/bg_service_04.png);
        }
      }
    }
  }

  &.service_child {
    .visual_area {
      background-color: #eff8f9;

      .inner {
        background-image: url(../img/pc/bg_service_05.png);

        @include mobile {
          background-image: url(../img/mo/bg_service_05.png);
        }
      }
    }
  }
}

.apply {
  .section_article {
    padding-bottom: 100px;

    @include tablet {
      padding-top: 66px;
    }

    @include mobile {
      padding: 70px 0 60px;
    }
  }

  .visual_area {
    height: 280px;
    background: #21232d url(../img/pc/bg_apply_graphic.png) no-repeat 50% 50%;
    @include vender-prefix(background-size, 2200px 280px);

    @include tablet {
      background-image: url(../img/tablet/bg_apply_graphic.png);
      @include vender-prefix(background-size, 1160px 280px);
    }

    @include mobile {
      height: 248px;
      background-image: url(../img/mo/bg_apply_graphic.png);
      @include vender-prefix(background-size, 767px 248px);
    }

    .main_title {
      padding-top: 100px;
      font-family: 'Calibre';
      font-weight: 700;
      font-size: 44px;
      color: #fff;
      line-height: 44px;

      @include tablet {
        padding-top: 92px;
        font-size: 36px;
        line-height: 36px;
      }

      @include mobile {
        padding-top: 64px;
        font-size: 34px;
        line-height: 34px;
      }
    }

    .main_txt {
      margin-top: 10px;
      font-size: 16px;
      color: #848484;
      line-height: 26px;

      @include tablet {
        font-size: 15px;
        line-height: 25px;
      }

      @include mobile {
        margin-top: 8px;
        font-size: 14px;
        line-height: 22px;

        .m_br {
          display: block;
        }
      }
    }
  }

  .slider {
    padding: 80px 0 0;

    @include tablet {
      padding-top: 69px;
    }

    @include mobile {
      padding-top: 48px;
    }

    .slider_title {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 36px;
      line-height: 52px;
      letter-spacing: -1px;

      @include tablet {
        top: -4px;
        left: 32px;
        font-size: 30px;
        line-height: 44px;
        letter-spacing: -0.5px;
      }

      @include mobile {
        position: static;
        font-size: 23px;
        line-height: 25px;
      }
    }

    .apply_slider {
      padding-left: 396px;

      @include tablet {
        padding-left: 232px;
      }

      @include mobile {
        margin-top: 28px;
        padding-left: 0;
      }

      .list_title {
        display: block;
        font-size: 24px;
        color: #1ace5e;
        line-height: 34px;
        letter-spacing: -0.5px;

        @include tablet {
          font-size: 20px;
          line-height: 30px;
        }

        @include mobile {
          font-size: 18px;
          line-height: 20px;
        }
      }

      .list_txt {
        margin-top: 20px;
        font-size: 15px;
        color: #848484;
        line-height: 24px;

        @include mobile {
          margin-top: 16px;
        }

        strong {
          color: #000;
        }
      }
    }
  }

  .apply_list {
    word-break: break-all;

    @at-root .board_top {
      position: relative;

      .list_title {
        font-size: 30px;
        line-height: 52px;

        @include tablet {
          font-size: 24px;
        }

        @include mobile {
          font-size: 22px;
          line-height: 25px;
        }
      }

      .top_util {
        position: absolute;
        top: 0;
        right: 0;

        @include mobile {
          position: static;
        }

        .menu_list {
          margin: 20px 1px 0 0;
          @include clear;

          @include tablet {
            margin-top: 17px;
          }

          @include mobile {
            margin-top: 19px;
          }

          .item {
            float: left;

            + .item {
              position: relative;
              padding-left: 41px;

              @include mobile {
                padding-left: 25px;
              }

              &:before {
                content: '';
                position: absolute;
                left: 21px;
                width: 1px;
                height: 100%;
                background-color: #dedede;

                @include mobile {
                  top: 2px;
                  left: 12px;
                  height: 14px;
                }
              }
            }

            &.on {
              text-decoration: underline;
            }

            a {
              font-size: 16px;
              color: #333;

              @include mobile {
                font-size: 14px;
                color: #1ace5e;
                line-height: 16px;
              }
            }
          }
        }

        .btn_return {
          margin-top: 6px;

          @include mobile {
            margin-top: 19px;
            font-size: 14px;
            color: #1ace5e;
            line-height: 14px;

            &:before {
              margin-right: 6px;
              vertical-align: -2px;
            }
          }
        }
      }
    }

    @at-root .board_content {
      margin-top: 13px;
      border-top: 2px solid #333;

      @include tablet {
        margin-top: 10px;
      }

      @include mobile {
        margin-top: 20px;
      }

      .title {
        display: inline-block;
        max-width: 100%;
        font-size: 18px;
        line-height: 28px;
        vertical-align: middle;
        @include ellipsis;

        @include tablet {
          letter-spacing: -0.5px;
        }

        @include mobile {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .date {
        position: absolute;
        top: 37px;
        right: 0;
        font-size: 16px;
        color: #848484;
        line-height: 18px;

        @include mobile {
          position: static;
          display: block;
          margin-top: 6px;
          font-size: 13px;
          line-height: 14px;
        }
      }

      .board_list {
        .item {
          border-bottom: 1px solid #dedede;
        }

        .link {
          position: relative;
          display: block;
          padding: 32px 220px 31px 0;
          padding-right: 220px;

          &:hover {
            .title {
              color: #1ace5e;
            }
          }

          @include mobile {
            padding: 19px 0 20px 1px;
          }
        }

        .title {
          display: block;

          @include mobile {
            white-space: normal;
            @include ellipsis2;
          }
        }
      }

      .btn_box {
        margin-top: 40px;
        text-align: center;

        @include mobile {
          margin-top: 33px;
        }
      }

      .detail_title {
        position: relative;
        padding: 47px 230px 46px 3px;

        @include tablet {
          padding: 39px 230px 37px 3px;
        }

        @include mobile {
          padding: 20px 0;
        }

        .d_day {
          float: right;
          margin: 4px 0 -4px 12px;
          font-family: Calibre;
          font-weight: 700;
          font-size: 26px;
          color: #1ace5e;
          letter-spacing: -0.5px;

          @include mobile {
            margin: 2px 0 -2px 8px;
            font-size: 21px;
            line-height: 21px;
          }
        }

        .title {
          font-size: 24px;
          letter-spacing: -0.5px;

          @include mobile {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0;
          }

          &:hover {
            color: #000;
          }
        }

        .date {
          top: 52px;

          @include tablet {
            top: 44px;
          }

          @include mobile {
            margin-top: 9px;
          }
        }
      }

      .detail_content {
        padding: 64px 0 80px;
        border: 1px solid #dedede;
        border-width: 1px 0;

        @include tablet {
          padding-top: 53px;
        }

        @include mobile {
          padding: 40px 0 42px;
        }
      }

      .detail_info {
        font-size: 16px;
        line-height: 28px;
        color: #848484;

        @include mobile {
          font-size: 14px;
          line-height: 24px;
        }

        + .detail_info {
          margin-top: 57px;

          @include mobile {
            margin-top: 48px;
          }
        }
      }

      .btn_area {
        margin-top: 66px;
        text-align: center;

        @include media-query(1279) {
          display: none;
        }

        .btn_link {
          padding: 0 68px;
        }
      }

      .content_bottom {
        display: none;
        padding: 32px 0 31px;
        border-bottom: 1px solid #dedede;

        @include media-query(1279) {
          display: block;
        }

        @include mobile {
          position: relative;
        }

        .notice_txt {
          @include media-query(1279) {
            font-size: 14px;
            color: #1ace5e;
            line-height: 24px;
          }
        }

        .btn_share {
          display: none;

          @include mobile {
            display: block;
            position: absolute;
            top: 24px;
            right: 0;
          }
        }
      }
    }

    .board_bottom {
      margin-top: 27px;
      @include clear;

      @include mobile {
        margin-top: 16px;

        &:after {
          display: none;
        }
      }

      .share_link {
        float: left;

        @include mobile {
          display: none;
        }

        .item {
          float: left;

          + .item {
            margin-left: 10px;
          }

          a {
            display: block;
          }
        }

        .facebook {
          @include sp-desktop('ico_sns_facebook');
        }

        .twitter {
          @include sp-desktop('ico_sns_twitter');
        }

        .blog {
          @include sp-desktop('ico_sns_blog');
        }

        .band {
          @include sp-desktop('ico_sns_band');
        }

        .url {
          @include sp-desktop('ico_url');
        }
      }

      .paging_btn {
        float: right;

        @include mobile {
          float: none;
          @include clear;
        }

        .btn_prev {
          float: left;
        }

        .btn_next {
          float: right;

          @include mobile {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.news {
  .section_article {
    padding: 72px 0 100px;

    @include tablet {
      padding: 63px 0 80px;
    }

    @include mobile {
      padding: 40px 0 60px;
    }
  }

  .news_list {
    margin: -32px -20px;
    @include clear;

    @include tablet {
      margin: -30px -12px;
    }

    @include mobile {
      margin: 0;

      &:after {
        display: none;
      }
    }

    .item {
      float: left;
      width: 360px;
      // margin: 32px 20px 28px;
      margin: 32px 20px 35px;

      @include tablet {
        width: calc(50% - 24px);
        margin: 30px 12px;
      }

      @include mobile {
        float: none;
        width: 100%;
        min-height: 0;
        margin: 0;

        + .item {
          margin-top: 40px;
        }
      }

      a {
        display: block;
      }

      .thum_img {
        position: relative;
        width: 360px;
        height: 206px;
        background-color: #5ac467;
        @include vender-prefix(background-size, 360px 206px);

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          @include vender-prefix(transform, translate(-50%, -50%));
          @include sp-desktop('img_news_logo');
        }

        @include media-query(1279) {
          position: relative;
          width: 100%;
          height: auto;
          padding-top: 57.06%;
          @include vender-prefix(background-size, cover);
        }

        @include mobile {
          padding-top: 56.885%;
        }

        img {
          position: relative;
          z-index: 1;

          @include media-query(1279) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .list_title {
      min-height: 56px;
      max-height: 56px;
      margin-top: 24px;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.5px;
      @include ellipsis2;

      @include mobile {
        margin-top: 20px;
      }
    }

    .list_desc {
      max-height: 56px;
      margin-top: 20px;
      font-size: 15px;
      line-height: 24px;
      color: #848484;
      @include ellipsis2;

      @include mobile {
        margin-top: 12px;
      }
    }

    .date {
      display: block;
      // margin-top: 21px;
      margin-top: 15px;
      font-family: Calibre;
      font-weight: normal;
      font-size: 18px;
      color: #848484;
      line-height: 16px;

      @include mobile {
        margin-top: 8px;
      }
    }
  }

  .btn_box {
    margin-top: 60px;
    text-align: center;

    @include tablet {
      margin-top: 40px;
    }

    @include mobile {
      margin-top: 24px;
    }
  }

  .news_detail {
    word-break: break-all;

    .board_content {
      margin-top: 48px;

      @include tablet {
        margin-top: 32px;
      }

      @include mobile {
        margin-top: 18px;
      }

      .detail_title {
        @include tablet {
          padding: 36px 0;
        }
      }

      .title {
        overflow: visible;
        display: block;
        max-width: 100%;
        white-space: normal;

        @include tablet {
          line-height: 32px;
        }
      }

      .date {
        @include tablet {
          position: static;
          display: block;
          margin-top: 12px;
        }
      }

      .btn_area {
        margin-top: 75px;

        @include tablet {
          display: block;
        }
      }

      .btn_link {
        padding: 0 64.5px;
        font-size: 18px;
      }
    }

    .detail_content {
      @include mobile {
        border-bottom: 0;
      }

      & > a {
        margin-top: 30px;
        line-height: 28px;
        text-decoration: underline;
        color: #1ace5e;

        @include mobile {
          margin-top: 24px;
          font-size: 14px;
          line-height: 24px;
        }
      }

      p {
        color: #848484;
        font-size: 16px;
        line-height: 28px;

        @include mobile {
          font-size: 14px;
          line-height: 24px;
        }
      }

      img {
        max-width: 100%;
      }
    }

    .board_top {
      .top_util {
        @include mobile {
          margin-top: 20px;
        }

        .btn_return {
          margin-top: 0;
        }
      }

      .list_title {
        font-family: Calibre;
        font-size: 38px;
        font-weight: 700;
        line-height: 38px;

        @include tablet {
          font-size: 36px;
        }

        @include mobile {
          font-size: 30px;
          line-height: 31px;
        }
      }
    }

    .board_bottom {
      margin-top: 27px;

      @include clear;

      @include mobile {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #dedede;

        &:after {
          display: none;
        }
      }

      .paging_btn {
        float: right;

        @include mobile {
          float: none;
          @include clear;
        }

        .btn_prev {
          float: left;
        }

        .btn_next {
          float: right;

          @include mobile {
            margin-left: 0;
          }
        }
      }
    }

    .share_area {
      float: left;
      margin-top: 27px;

      @include mobile {
        float: none;
        margin-top: 17px;
        text-align: center;
      }
    }

    .share_link {
      @include clear;

      display: inline-block;

      .item {
        float: left;

        + .item {
          margin-left: 10px;
        }

        a {
          display: block;
        }
      }

      .facebook {
        @include sp-desktop('ico_sns_facebook');
      }

      .twitter {
        @include sp-desktop('ico_sns_twitter');
      }

      .blog {
        @include sp-desktop('ico_sns_blog');
      }

      .band {
        @include sp-desktop('ico_sns_band');
      }

      .url {
        @include sp-desktop('ico_url');
      }
    }
  }
}

.contact {
  .section_article {
    padding-top: 72px;

    @include tablet {
      padding-top: 63px;
    }

    @include mobile {
      padding-top: 40px;
    }
  }

  .contact_list {
    .item {
      @include clear;

      @include media-query(1279) {
        &:after {
          display: none;
        }
      }

      + .item {
        margin-top: 75px;

        @include tablet {
          margin-top: 58px;
        }

        @include mobile {
          margin-top: 60px;
        }
      }

      &:nth-child(1) {
        .map_box {
          float: left;
          margin-right: 80px;
        }

        .info_box {
          overflow: hidden;

          @include media-query(1279) {
            overflow: visible;
          }
        }

        .map_box {
          @include media-query(1279) {
            float: none;
          }
        }
      }

      &:nth-child(2) {
        .map_box {
          float: right;
        }

        .info_box {
          float: left;
        }

        .map_box,
        .info_box {
          @include media-query(1279) {
            float: none;
            width: 100%;
          }
        }
      }
    }
  }

  .map_box {
    width: 540px;

    @include media-query(1279) {
      width: 100%;
    }
  }

  .info_box {
    @include tablet {
      position: relative;
    }
  }

  .contact_title {
    display: block;
    margin-top: 14px;
    font-size: 26px;
    color: #1ace5e;
    line-height: 32px;

    @include tablet {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include mobile {
      margin-top: 24px;
      font-size: 22px;
      line-height: 32px;
    }
  }

  .contact_info {
    margin-top: 20px;

    @include tablet {
      padding-left: 164px;
    }

    dt,
    dd {
      border-bottom: 1px solid #eaeaea;
      font-size: 16px;
      line-height: 59px;

      @include mobile {
        margin-top: 16px;
        border-bottom: 0;
        font-size: 14px;
        line-height: 22px;
        word-break: keep-all;

        &:first-of-type {
          margin-top: 0;
        }
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    dt {
      float: left;
      width: 118px;
      color: #848484;

      @include mobile {
        width: 67px;
      }
    }

    dd {
      overflow: hidden;

      .email {
        text-decoration: underline;
      }
    }
  }

  .map {
    width: 100%;
    height: 300px;

    @include mobile {
      height: 200px;
    }
  }
}

.ethics {
  min-height: 100%;

  a {
    color: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
    font-weight: normal;
  }

  .container {
    padding-bottom: 80px;
    border-top: 1px solid #e6e6e6;
  }

  .content {
    margin: 0 auto;
    padding: 52px 0 80px;
    width: 930px;

    table {
      caption {
        display: none;
      }
    }
  }

  .cont_wrap2 {
    overflow: hidden;

    h3 {
      @include mobile {
        font-size: 23px;
      }

      font-size: 40px;
      color: #000;
      font-weight: normal;

      + {
        p {
          margin-top: 24px;
        }
      }
    }

    p {
      margin-top: 27px;
      font-size: 14px;
      line-height: 26px;
      color: #505b6c;
      font-weight: normal;
    }
  }

  .button {
    display: inline-block;
    min-width: 100px;
    height: 50px;
    padding: 0 20px;
    color: #505b6c;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 52px;
    border: 1px solid #e6e6e6;
  }

  .gray {
    background: #999fa9;
    color: #fff;
    border-color: #999fa9;
  }

  .btn_s {
    min-width: 88px;
    height: 36px;
    line-height: 38px;
    font-size: 14px;
  }
}

.privacy {
  a {
    color: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
  }

  .container {
    padding-bottom: 80px;
    border-top: 1px solid #e6e6e6;
  }

  .content {
    margin: 0 auto;
    padding: 52px 0 80px;
    width: 930px;

    table {
      caption {
        display: none;
      }
    }
  }

  .cont_box {
    font-weight: initial;
  }
}

.ethics,
.privacy {
  .content {
    @include media-query(1279) {
      width: 784px;
    }

    @include mobile {
      width: 88%;
      padding: 40px 0 30px;
    }
  }

  .cont_left {
    @include media-query(1279) {
      margin-right: 30px;
    }

    @include mobile {
      display: none;
    }

    float: left;
    width: 140px;
    margin-right: 62px;

    .cont_title {
      margin-top: 5px;
      font-size: 20px;
      line-height: 28px;
      color: #505b6c;
      font-weight: normal;

      .bar {
        display: block;
        width: 50px;
        height: 1px;
        margin: 15px 0 16px;
        background: #e6e6e6;
      }
    }

    p {
      margin-top: 10px;
      font-size: 13px;
      color: #666;
      line-height: 22px;
      font-weight: normal;
    }

    .cont_date {
      display: block;
    }
  }

  .cont_wrap2 {
    overflow: hidden;

    h3 {
      @include mobile {
        font-size: 23px;
      }

      font-size: 40px;
      color: #000;
      font-weight: normal;

      + {
        p {
          margin-top: 24px;
        }
      }
    }

    .btn_prev_area {
      overflow: hidden;
    }

    .btn_prev_privacy {
      position: relative;
      margin: 30px 0 20px;
      float: right;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #000;
      }
    }
  }

  .tbl_row {
    width: 100%;
    margin-top: 33px;
    border-top: 1px solid #999fa9;
    border-collapse: collapse;

    th {
      padding-left: 4%;
      border-bottom: 1px solid #e6e6e6;
      font-size: 14px;
      font-weight: normal;
      color: #000;
      text-align: left;
    }

    td {
      @include mobile {
        padding: 11px 15px;
      }

      height: 47px;
      padding: 16px 30px;
      border: 1px solid #e6e6e6;
      border-width: 0 0 1px 1px;
      font-size: 14px;
      color: #505b6c;
      line-height: 26px;
      font-weight: normal;
    }

    p {
      margin: 0;
    }

    .gray {
      margin-top: 8px;
    }
  }

  .lnk_area {
    margin-top: 8px;
    text-align: right;

    a {
      font-size: 13px;
      color: #808080;
      margin-left: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .new_list {
    .no_data {
      float: none;
      width: auto;
      text-align: center;

      p {
        padding: 155px 0 154px;
        font-size: 26px;
        color: #cfcfcf;
      }
    }
  }
}

.wrap[class*='research'] {
  .mo_br,
  .tb_br {
    display: none;
  }

  .pc_br,
  .pc_show {
    display: block;
  }

  @include tablet {
    padding-top: 152px;

    .mo_br,
    .pc_br {
      display: none;
    }

    .tb_br {
      display: block;
    }
  }

  @include mobile {
    padding-top: 140px;

    .tb_br,
    .pc_br {
      display: none;
    }

    .mo_br,
    .mo_show {
      display: block;
    }
  }

  .visual_area {
    background-color: #f8f7f0;

    .inner {
      height: 320px;
      background: url(../img/pc/bg_research_01.png) no-repeat 50% 50%;
      @include vender-prefix(background-size, 1160px 320px);

      @include mobile {
        height: 280px;
        background-image: url(../img/mo/bg_research_01.png);
        @include vender-prefix(background-size, 767px 280px);
      }
    }

    .main_title {
      padding-top: 126px;
      font-size: 26px;
      line-height: 36px;

      &.research_title {
        @include mobile {
          padding-top: 96px;
        }
      }

      &.performance_title {
        @include tablet {
          padding-top: 110px;
        }
      }

      @include tablet {
        font-size: 24px;
        line-height: 34px;
      }

      @include mobile {
        padding-top: 80px;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  &.research_contest {
    .visual_area {
      background-color: #eff8f9;

      .inner {
        background-image: url(../img/pc/bg_research_01.png);

        @include mobile {
          background-image: url(../img/mo/bg_research_01.png);
        }
      }
    }
  }

  &.research_public {
    .visual_area {
      background-color: #f0f3f8;

      .inner {
        background-image: url(../img/pc/bg_research_02.png);

        @include mobile {
          background-image: url(../img/mo/bg_research_02.png);
        }
      }
    }
  }

  &.research_performance {
    .btn_box {
      margin-top: 60px;
      text-align: center;

      @include tablet {
        margin-top: 40px;
      }

      @include mobile {
        margin-top: 24px;
      }
    }

    .visual_area {
      background-color: #eff8f9;

      .inner {
        background-image: url(../img/pc/bg_research_03.png);

        @include mobile {
          background-image: url(../img/mo/bg_research_03.png);
        }
      }
    }
  }

  &.research_faq {
    .visual_area {
      background-color: #f0f2fd;

      .inner {
        background-image: url(../img/pc/bg_research_04.png);

        @include mobile {
          background-image: url(../img/mo/bg_research_04.png);
        }
      }
    }
  }

  .prev_wrap {
    padding-top: 80px;

    .inner {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: #000;
      }

      @include tablet {
        padding: 0 32px;

        &::after {
          left: 32px;
          right: 32px;
        }
      }

      @include mobile {
        padding: 0 24px 24px;

        &::after {
          left: 24px;
          right: 24px;
        }
      }
    }

    .title {
      display: inline-block;
      margin: 74px 0 20px;
      font-weight: 800;
      font-size: 36px;
      color: #333;

      @include mobile {
        font-size: 22px;
      }
    }

    .dropdown {
      position: absolute;
      top: 0;
      right: 0;

      @include tablet {
        right: 32px;
      }

      @include mobile {
        top: -30px;
        left: 24px;
        right: 24px;
      }

      &.is-opened {
        .dropdown_opener::after {
          transform: rotate(180deg);
        }

        .dropdown_options {
          display: block;
        }
      }

      &_opener {
        display: block;
        position: relative;
        width: 180px;
        height: 33px;
        padding: 2px 0 11px;
        border-bottom: 4px solid #333;
        font-size: 16px;
        line-height: 18px;
        text-align: left;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 5px;
          right: 0;
          width: 18px;
          height: 11px;
          @include sp-desktop('ico_dropdown');
        }

        @include mobile {
          width: 100%;
          color: #087ce9;
        }
      }

      &_options {
        overflow-y: auto;
        display: none;
        position: relative;
        width: 100%;
        max-height: 240px;
        z-index: 2;
      }

      &_option {
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
        border-left: 1px solid #333;
        box-sizing: border-box;
      }

      &_link {
        display: block;
        padding: 0 16px;
        font-size: 15px;
        line-height: 47px;
        background-color: #fff;

        &:hover {
          color: #fff;
          background-color: #1ace5e;
        }
      }
    }

    .half {
      position: absolute;
      top: 93px;
      right: 0;
      z-index: 1;
      font-size: 0;

      @include tablet {
        right: 32px;
      }

      @include mobile {
        position: static;
      }

      &_item {
        display: inline-block;
        position: relative;
        padding: 0 20px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 19px;
          background-color: #dedede;
        }

        &:last-child {
          padding-right: 0;

          &::after {
            display: none;
          }
        }

        &.is-active {
          .half_link {
            color: #1ace5e;
          }
        }

        @include mobile {
          padding: 0 14px;

          &:first-child {
            padding-left: 0;
          }

          &::after {
            height: 14px;
          }
        }
      }

      &_link {
        font-weight: 800;
        font-size: 16px;
        color: #333;

        @include mobile {
          font-size: 14px;
        }
      }
    }

    + .section_article {
      @include mobile {
        padding-top: 37px;
      }
    }
  }

  .section_article {
    @include mobile {
      padding: 50px 0;
    }

    &.contest {
      .contest {
        &_main,
        &_procedure,
        &_info {
          position: relative;
        }

        &_procedure {
          margin-top: 154px;

          @include tablet {
            margin-top: 61px;
          }

          @include mobile {
            margin-top: 52px;
          }
        }

        &_info {
          margin-top: 100px;

          @include tablet {
            margin-top: 63px;
          }

          @include mobile {
            margin-top: 59px;
          }
        }

        &_prev {
          position: relative;
          margin-top: 80px;
          text-align: right;

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #e6e6e6;

            @include tablet {
              left: -32px;
              right: -32px;
            }

            @include mobile {
              left: -24px;
              right: -24px;
            }
          }

          a {
            display: inline-block;
            position: relative;
            margin-top: 32px;
            padding-right: 23px;
            font-weight: 800;
            font-size: 18px;
            color: #333;

            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              top: 0;
              right: 0;
              @include sp-desktop('ico_arrow');
            }

            @include mobile {
              font-size: 14px;
            }
          }
        }
      }
    }

    .con_left {
      position: absolute;
      top: 0;
      left: 0;
      width: 396px;

      @include media-query(1279) {
        position: relative;
        width: auto;
      }

      .title {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.5px;

        @include mobile {
          font-size: 20px;
          letter-spacing: -0.56px;
        }
      }
    }

    .con_right {
      padding-left: 396px;

      @include media-query(1279) {
        padding-left: 0;
      }
    }

    .date {
      position: absolute;
      top: -40px;
      left: -1px;
      display: block;
      font-family: Calibre;
      font-size: 36px;
      line-height: 34px;
      color: #1ace5e;

      em {
        position: relative;
        top: -3px;
        margin-left: 10px;
        font-size: 24px;
        font-family: NanumSquare, '나눔스퀘어', sans-serif;
      }

      @include tablet {
        position: static;
        font-size: 36px;
        line-height: 34px;

        em {
          font-size: 24px;
        }
      }

      @include mobile {
        position: static;
        font-size: 28px;
        line-height: 34px;

        em {
          top: -2px;
          margin-left: 8px;
          font-size: 19px;
        }
      }
    }

    .state_badge {
      position: absolute;
      display: block;
      bottom: -46px;
      min-width: 79px;
      height: 32px;
      padding: 5.5px 20px;
      font-size: 14px;
      line-height: 21px;
      border-radius: 16px;
      background: #1ace5e;
      box-sizing: border-box;

      @include tablet {
        position: static;
        display: inline-block;
        margin-top: 10px;
      }

      @include mobile {
        position: static;
        display: inline-block;
        min-width: auto;
        height: 30px;
        padding: 5px 17px;
        font-size: 13px;
        margin-top: 8px;
      }

      &.s-close {
        background: #bfbfbf;
      }

      &.s-ongoing {
        background: #000;
      }

      span {
        display: inline-block;
        text-align: center;
        color: #fff;
      }
    }

    .main_title {
      display: block;
      font-size: 36px;
      line-height: 52px;
      letter-spacing: -1px;

      @include tablet {
        font-size: 30px;
        line-height: 44px;
        letter-spacing: -0.5px;
      }

      @include mobile {
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0px;
      }
    }

    .main_desc {
      font-size: 16px;
      line-height: 28px;
      color: #848484;

      @include tablet {
        padding-top: 33px;
        word-break: keep-all;
      }

      @include mobile {
        padding-top: 33px;
        font-size: 14px;
        line-height: 24px;
        word-break: normal;
      }
    }

    .title_wrap {
      display: table-cell;
      width: 120px;
      padding-right: 10px;
      vertical-align: top;

      @include tablet {
        width: 100px;
        padding-right: 40px;
      }

      @include mobile {
        display: block;
        width: 100%;
        padding-right: 0;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0px;
      }
    }

    .title {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.5px;

      @include mobile {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0px;
      }
    }

    .desc_wrap {
      display: table-cell;

      @include mobile {
        display: block;
        width: 100%;
        padding-top: 10px;
      }
    }

    .desc {
      font-size: 16px;
      line-height: 28px;
      color: #848484;

      @include mobile {
        font-size: 14px;
        line-height: 24px;
      }

      &_lists {
        display: block;
        padding-top: 16px;
        font-size: 16px;
        line-height: 28px;
        color: #848484;

        &:first-child {
          padding-top: 0px;
        }

        @include mobile {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    .exam {
      font-size: 16px;
      line-height: 28px;
      color: #848484;

      @include mobile {
        font-size: 14px;
        line-height: 24px;
      }

      &_title {
        color: #000;
        padding-right: 15px;

        @include tablet {
          display: block;
          width: 100%;
          margin-top: 16px;
          padding-right: 0;
        }

        @include mobile {
          display: block;
          width: 100%;
          margin-top: 10px;
          padding-right: 0;
        }
      }
    }

    .connect_link {
      text-decoration: underline;
    }

    .procedure_list {
      font-size: 0;
      box-sizing: border-box;

      @include tablet {
        padding-top: 24px;
      }

      .item {
        position: relative;
        display: inline-block;
        width: 19.7%;
        font-size: 15px;
        line-height: 21px;
        box-sizing: border-box;

        &::before {
          display: block;
          box-sizing: border-box;
          content: '';
        }

        .step {
          position: relative;
          display: inline-block;
          padding-top: 25px;
          font-family: Calibre;
          letter-spacing: 1px;

          &:after {
            position: absolute;
            bottom: 1px;
            display: block;
            width: 100%;
            height: 2px;
            background: #000;
            content: '';
          }

          @include tablet {
            padding-top: 27px;
          }

          @include mobile {
            padding-top: 17px;
          }
        }

        .pharse {
          display: block;
          max-width: 94px;
          padding-top: 16px;

          @include mobile {
            padding-top: 12px;
          }
        }

        &:nth-child(3) {
          .text {
            max-width: 94px;
          }
        }

        @include tablet {
          width: 20%;
        }

        @include mobile {
          width: 33.333%;
          font-size: 14px;
          padding-top: 28px;
        }
      }

      .sub-pharse {
        display: block;
        padding-top: 8px;
        font-size: 14px;
        line-height: 21px;

        @include tablet {
          padding-top: 10px;
        }

        @include mobile {
          font-size: 13px;
          padding-top: 5px;
        }
      }

      &.research_procedure {
        @include tablet {
          padding-top: 40px;
        }

        @include mobile {
          padding-top: 0px;
        }

        .item {
          &.step01 {
            &:before {
              @include sp-desktop('ico_contest_01');

              @include mobile() {
                @include sp-mobile('ico_contest_01');
              }
            }
          }

          &.step02 {
            &:before {
              @include sp-desktop('ico_contest_02');

              @include mobile() {
                @include sp-mobile('ico_contest_02');
              }
            }
          }

          &.step03 {
            &:before {
              @include sp-desktop('ico_contest_03');

              @include mobile() {
                @include sp-mobile('ico_contest_03');
              }
            }
          }

          &.step04 {
            &:before {
              @include sp-desktop('ico_contest_04');

              @include mobile() {
                @include sp-mobile('ico_contest_04');
              }
            }
          }

          &.step05 {
            &:before {
              @include sp-desktop('ico_contest_05');

              @include mobile() {
                @include sp-mobile('ico_contest_05');
              }
            }
          }
        }
      }

      &.apply_procedure {
        padding-top: 40px;

        @include tablet {
          padding-top: 29px;
        }

        @include mobile {
          padding-top: 0px;
        }

        .pharse {
          max-width: 72px;
          padding-top: 14px;
        }

        .item {
          @include mobile {
            padding-top: 25px;
          }

          &.step01 {
            &:before {
              @include sp-desktop('ico_public_01');

              @include mobile() {
                @include sp-mobile('ico_public_01');
              }
            }
          }

          &.step02 {
            &:before {
              @include sp-desktop('ico_public_02');

              @include mobile() {
                @include sp-mobile('ico_public_02');
              }
            }
          }

          &.step03 {
            &:before {
              @include sp-desktop('ico_public_03');

              @include mobile() {
                @include sp-mobile('ico_public_03');
              }
            }
          }

          &.step04 {
            &:before {
              @include sp-desktop('ico_public_04');

              @include mobile() {
                @include sp-mobile('ico_public_04');
              }
            }
          }

          &.step05 {
            &:before {
              @include sp-desktop('ico_public_05');

              @include mobile() {
                @include sp-mobile('ico_public_05');
              }
            }
          }
        }
      }
    }

    .contest_item {
      display: table;
      width: 100%;
      padding-top: 41px;
      font-size: 0;

      @include tablet {
        padding-top: 30px;
      }

      @include mobile {
        display: block;
        padding-top: 32px;
      }

      &.field {
        padding-top: 4px;

        @include tablet {
          padding-top: 30px;
        }

        @include mobile {
          padding-top: 28px;
        }
      }

      &.qualification {
        @include tablet {
          margin-top: 0;
        }

        @include mobile {
          padding-top: 40px;
        }
      }

      .schedule {
        &_title {
          color: #000;
          padding-right: 15px;
        }
      }

      .support_money {
        color: #1ace5e;
      }

      .connect_email {
        text-decoration: underline;
      }

      .field {
        &_lists {
          font-size: 0;
          margin: 0 0 0 -28px;

          @include tablet {
            margin: 0 0 0 -28px;
          }

          @include mobile {
            margin: -20px 0 0 -15px;
            padding-top: 24px;
          }
        }

        &_list {
          display: inline-block;
          margin: 24px 0 0 28px;
          box-sizing: border-box;

          @include tablet {
            margin: 0;
            width: 50%;
          }

          @include mobile {
            margin: 0;
            width: 50%;
          }

          &--wrap {
            width: 303px;

            @include tablet {
              width: auto;
              margin: 24px 0 0 28px;
            }

            @include mobile {
              width: auto;
              margin: 20px 0 0 15px;
            }
          }

          &.entry {
            .field_thumb {
              &:after {
                @include sp-desktop('img_ci_entry');

                @include mobile() {
                  @include sp-mobile('img_ci_entry');
                }
              }
            }
          }

          &.boostcourse {
            .field_thumb {
              &:after {
                @include sp-desktop('img_ci_boostcourse');

                @include mobile() {
                  @include sp-mobile('img_ci_boostcourse');
                }
              }
            }
          }

          &.edwith {
            .field_thumb {
              &:after {
                @include sp-desktop('img_ci_edwith');

                @include mobile() {
                  @include sp-mobile('img_ci_edwith');
                }
              }
            }
          }

          &.sw {
            .field_thumb {
              &:after {
                @include sp-desktop('img_ci_sw');

                @include mobile() {
                  @include sp-mobile('img_ci_sw');
                }

                @include tablet {
                  margin-top: 10px;
                }
              }
            }
          }

          &.boostcamp {
            .field_thumb {
              &:after {
                @include sp-desktop('img_ci_boostcamp');

                @include mobile() {
                  @include sp-mobile('img_ci_boostcamp');
                }
              }
            }
          }

          &.jrnaver {
            .field_thumb {
              &:after {
                @include sp-desktop('img_ci_jrnaver');

                @include mobile() {
                  @include sp-mobile('img_ci_jrnaver');
                }
              }
            }
          }

          &.jr_school {
            .field_thumb {
              &:after {
                @include sp-desktop('img_ci_jr_school');

                @include mobile() {
                  @include sp-mobile('img_ci_jr_school');
                }
              }
            }
          }
        }

        &_thumb {
          width: 303px;
          height: 150px;
          text-align: center;
          overflow: hidden;
          border: 1px solid #eee;
          border-radius: 10px;
          box-sizing: border-box;

          @include tablet {
            width: 100%;
            height: 150px;
          }

          @include mobile {
            width: 100%;
            height: 100px;
          }

          img {
            display: block;
            width: 100%;
            height: auto;
          }

          &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }

          &:after {
            display: inline-block;
            vertical-align: middle;
            content: '';
          }
        }

        &_name {
          font-size: 15px;
          padding-top: 14px;
          color: #333333;

          @include mobile {
            font-size: 14px;
            line-height: 24px;
            padding-top: 8px;
          }
        }
      }
    }

    .contest_apply {
      margin-top: 120px;

      .apply_btn {
        min-width: 140px;
        background: #000;
        color: #fff;
        padding: 15px 30px;
        border-radius: 30px;
      }
    }

    &.public {
      .public {
        &_main,
        &_procedure {
          position: relative;
        }

        &_main {
          .main_desc {
            @include tablet {
              padding-top: 16px;
            }

            @include mobile {
              padding-top: 18px;
            }
          }
        }

        &_procedure {
          margin-top: 102px;

          @include tablet {
            margin-top: 61px;
          }

          @include mobile {
            margin-top: 53px;
          }
        }
      }
    }

    .public_info {
      margin: 50px -14px 0;

      @include tablet {
        margin: 0px -14px 0;
      }

      @include mobile {
        margin: 0px;
      }

      .box_contents {
        font-size: 0;

        .info {
          &_box {
            display: inline-block;
            width: 368px;
            height: 361px;
            margin: 0 14px;
            vertical-align: top;
            @include box-sizing;

            &--wrap {
              width: 100%;
              height: 100%;
              padding: 30px 36px;
              border: 1px solid #dedede;
              @include border-radius(8px);
              @include box-sizing;

              @include tablet {
                height: 100%;
                width: auto;
                padding: 31px 35px;
                margin: 0 14px;
              }

              @include mobile {
                height: 100%;
                padding: 30px 0 0 27px;
              }
            }

            @include tablet {
              width: 50%;
              margin: 0;
            }

            @include mobile {
              width: 100%;
              min-width: 327px;
              height: 336px;
              min-height: 336px;
              margin: 0;

              + .info_box {
                margin-top: 16px;
              }
            }
          }

          &_title {
            display: block;
            font-size: 20px;
            color: #1ace5e;

            @include tablet {
              letter-spacing: 0px;
            }

            @include mobile {
              font-size: 18px;
              letter-spacing: -0.5px;
            }
          }

          &_desc {
            padding-top: 24px;

            @include tablet {
              padding-top: 22px;
            }

            @include mobile {
              padding-top: 25px;
            }

            dt {
              padding-top: 24px;
              color: #848484;

              &:first-child {
                padding-top: 0;
              }

              @include tablet {
              }

              @include mobile {
                padding-top: 16px;
                font-size: 14px;
                line-height: 24px;
              }
            }

            dd {
              padding-top: 4px;

              @include tablet {
              }

              @include mobile {
                padding-top: 4px;
                font-size: 14px;
                line-height: 24px;
              }

              + dd {
                padding-top: 0px;
              }
            }
          }
        }
      }
    }

    .public_item {
      &.research-data {
        .title_wrap {
          display: block;
          width: 100%;
          padding-right: 0;
        }

        @include tablet {
          padding-top: 31px;
        }

        @include mobile {
          padding-top: 26px;
        }
      }

      &.study-data {
        @include tablet {
          padding-top: 33px;
        }

        @include mobile {
          padding-top: 26px;
        }
      }

      @include tablet {
        padding-top: 40px;
      }

      @include mobile {
        padding-top: 32px;
      }
    }

    &.performance {
      .main_desc {
        text-align: center;
        padding: 246px 0;

        @include tablet {
          padding: 280px 0;
        }

        @include mobile {
          padding: 140px 0;
        }

        .info {
          font-size: 18px;
          line-height: 28px;
          letter-spacing: -0.5px;
          color: #000;

          @include mobile {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0px;
          }
        }
      }
    }

    .performance_panel {
      float: right;
      position: relative;
      z-index: 1;
      // width: 400px;
      margin-top: 9px;
      font-size: 0;

      .panel--inner {
        @include clear;
      }

      @include box-sizing;

      @include tablet {
        width: auto;
        margin-top: 9px;
      }

      @include mobile {
        float: none;
        margin: -4px -10px 0;
      }

      .panel_wrap {
        display: inline-block;
        vertical-align: top;
        @include box-sizing;

        @include mobile {
          width: 50%;
        }

        &--wrap {
          @include mobile {
            margin: 0 10px;
          }
        }

        &.is-opened {
          .category {
            &_select {
              &:after {
                transform: rotate(180deg);
              }
            }

            &_list_wrap {
              display: block;
            }
          }
        }

        .category {
          &_select {
            position: relative;
            display: block;
            width: 180px;
            height: 33px;
            padding: 2px 0 11px;
            border-bottom: 3px solid #333;
            font-size: 16px;
            line-height: 18px;
            text-align: left;

            @include mobile {
              width: 100%;
              padding: 2px 0 8px;
            }

            &:after {
              position: absolute;
              display: block;
              top: 5px;
              right: 0;
              width: 18px;
              height: 11px;
              @include sp-desktop('ico_dropdown');
              content: '';
            }

            .select-pharse {
              color: #333;
              pointer-events: none;
            }
          }

          &_list_wrap {
            position: relative;
            width: 100%;
            display: none;
            margin-top: 0px;
          }

          &_list {
            position: absolute;
            width: 100%;
            border: 1px solid #333;
            box-sizing: border-box;
          }

          &_item {
            text-align: left;
            background: #fff;

            + .category_item {
              border-top: 1px solid #333;
            }

            &:hover {
              background-color: #1ace5e;

              .category_link {
                color: #fff;
              }
            }
          }

          &_link {
            display: block;
            padding: 0 16px;
            font-size: 15px;
            line-height: 47px;
            color: #333;
          }
        }
      }
    }

    .performance_info {
      .main_title {
        display: inline-block;

        @include mobile {
          display: block;
          font-size: 22px;
          line-height: 32px;
        }
      }

      .main_text {
        display: inline-block;
        padding-left: 20px;
        font-size: 16px;
        line-height: 28px;
        color: #848484;

        @include mobile {
          margin-top: 12px;
          padding-left: 0;
          font-size: 14px;
          line-height: 24px;
        }
      }

      @include mobile {
        margin-top: 40px;
      }
    }

    .performance_list {
      &--wrap {
        margin-top: -18px;

        @include tablet {
          margin-top: 0;
        }

        @include mobile {
          margin-top: 0;
        }
      }

      margin: 0px 0 0 -40px;
      overflow: hidden;

      @include tablet {
        margin: 0px 0 0 -24px;
      }

      @include mobile {
        margin: 0;
      }

      @include clear;
      font-size: 0;

      .item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        margin: 60px 0 0 40px;
        color: #848484;
        width: 360px;

        @include tablet {
          margin: 60px 0 0 24px;
          width: calc(50% - 24px);

          &:first-child,
          &:nth-child(2) {
            margin-top: 40px;
          }
        }

        @include mobile {
          margin: 0;
          width: 100%;
          margin-top: 40px;
        }

        a {
          display: block;
        }

        &_thumb {
          overflow: hidden;
          position: relative;
          width: 360px;
          height: 206px;

          @include tablet {
            width: auto;
            height: 100%;
            min-height: 194px;
          }

          @include mobile {
            width: auto;
            height: 100%;
          }

          &.entry {
            &::after {
              @include sp-desktop('ico_research_performance_entry');

              @include mobile() {
                @include sp-mobile('ico_research_performance_entry');
              }
            }
          }

          &.boostcourse {
            &::after {
              @include sp-desktop('ico_research_performance_boostcourse');

              @include mobile() {
                @include sp-mobile('ico_research_performance_boostcourse');
              }
            }
          }

          &.edwith {
            &::after {
              @include sp-desktop('ico_research_performance_edwith');

              @include mobile() {
                @include sp-mobile('ico_research_performance_edwith');
              }
            }
          }

          &.boostcamp {
            &::after {
              @include sp-desktop('ico_research_performance_boostcamp');

              @include mobile() {
                @include sp-mobile('ico_research_performance_boostcamp');
              }
            }
          }

          &.sw {
            &::after {
              @include sp-desktop('ico_research_performance_sw');

              @include mobile() {
                @include sp-mobile('ico_research_performance_sw');
              }
            }
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 140px;
            height: 50px;
          }

          img {
            width: 100%;
            height: auto;
          }

          .thumb_text {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0 30px 26px 30px;
            color: #fff;

            @include tablet {
              padding: 0 20px 16px 20px;
            }

            @include mobile {
              padding: 0 16px 14px 16px;
            }

            span {
              display: block;
              font-family: NanumSquare;
              font-size: 15px;
              line-height: 24px;

              &:after {
                content: '';
                display: block;
                width: 24px;
                height: 1px;
                background: #fff;
                margin: 10px 0;
              }
            }

            p {
              font-family: NanumSquare;
              font-size: 18px;
              line-height: 28px;
              letter-spacing: -0.5px;
            }

            &.type_black {
              color: #000;

              span {
                &:after {
                  background: #000;
                }
              }
            }
          }
        }

        &_names {
          padding-top: 24px;
          font-size: 18px;
          line-height: 28px;
          letter-spacing: -0.5px;
          color: #000;

          span {
            padding-right: 4px;
          }

          @include mobile {
            padding-top: 20px;
          }
        }

        &_date {
          display: block;
          padding-top: 20px;
          font-family: Calibre;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0.5px;

          @include mobile {
            padding-top: 8px;
          }
        }
      }
    }

    .more-btn {
      padding-top: 60px;

      @include tablet {
        padding-top: 60px;
      }

      @include mobile {
        padding-top: 20px;
      }

      .btn {
        display: block;
        margin: 0 auto;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        box-sizing: border-box;

        &:after {
          content: '';
          display: block;
          @include sp-desktop('ico_more_btn_2');

          @include mobile {
            @include sp-mobile('ico_more_btn_2');
          }
        }

        @include mobile {
          width: 40px;
          height: 40px;
        }
      }
    }

    .apply_btn {
      .btn {
        display: inline-block;
        min-width: 120px;
        padding: 18px 44px;
        text-align: center;
        border-radius: 44px;
        background-color: #000;

        span {
          font-size: 18px;
          color: #fff;

          @include mobile {
            font-size: 14px;
          }
        }

        .close_text {
          display: none;
        }

        &.close {
          background-color: #bfbfbf;
          pointer-events: none;

          .close_text {
            display: inline;
          }

          @include hover {
            background-color: #bfbfbf;
          }
        }

        @include hover {
          background-color: #1ace5e;
          @include vender-prefix(transition, background-color 0.2s);
        }

        @include mobile {
          padding: 15px 40px;
        }
      }

      &.contest_item {
        padding-top: 94px;
      }

      &.public_item {
        padding-top: 50px;
      }

      @include tablet {
        &.contest_item {
          display: block;
          width: auto;
          padding-top: 40px;
          padding-left: 140px;
          text-align: left;
        }

        &.public_item {
          padding-top: 40px;
          padding-left: 141px;
        }
      }

      @include mobile {
        &.contest_item {
          padding-top: 40px;
          text-align: center;
        }

        &.public_item {
          padding-top: 40px;
          text-align: center;
        }
      }
    }

    .apply_info {
      padding-top: 24px;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0;
      text-align: left;
      color: #848484;
      word-break: keep-all;

      @include mobile {
        padding-top: 20px;
        font-size: 13px;
        line-height: 21px;
        text-align: left;
      }
    }
  }
}

.wrap[class*='research_faq'] {
  .visual_area .inner {
    display: flex;
    align-items: center;

    .main_title {
      padding-top: 0;
    }
  }

  .faq {
    padding: 125px 0 140px;

    @include mobile {
      padding: 56px 0 110px;
    }

    .title {
      font-weight: 700;
      font-size: 38px;
      font-family: 'Calibre';

      @include mobile {
        font-size: 30px;
      }
    }

    &-tab {
      position: absolute;
      top: 3px;
      right: 0;
      z-index: 1;
      font-size: 0;

      @include tablet {
        right: 32px;
      }

      @include mobile {
        position: static;
        margin-top: 20px;
      }

      &_item {
        display: inline-block;
        position: relative;
        padding: 0 20px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 19px;
          background-color: #dedede;
        }

        &:last-child {
          padding-right: 0;

          &::after {
            display: none;
          }
        }

        &.is-active {
          .faq-tab_link {
            color: #1ace5e;
          }
        }

        @include mobile {
          padding: 0 14px;

          &::after {
            height: 14px;
          }

          &:first-child {
            padding-left: 0;
          }
        }
      }

      &_link {
        font-weight: 800;
        font-size: 16px;
        color: #333;

        @include mobile {
          font-size: 14px;
        }
      }
    }

    &_contents {
      margin-top: 20px;
      border-top: 2px solid #000;
    }

    &-subtab {
      padding: 32px 0;
      border-bottom: 1px solid #e6e6e6;
      font-size: 0;

      @include mobile {
        padding: 22px 0;
      }

      &_item {
        display: inline-block;

        &.is-selected {
          .faq-subtab_btn {
            border-radius: 32px;
            background-color: #1ace5e;
            color: #fff;

            @include mobile {
              border-radius: 30px;
            }
          }
        }
      }

      &_btn {
        display: block;
        padding: 13px 24px;
        font-size: 16px;
        color: #333;

        @include mobile {
          padding: 8px 13.5px;
          font-size: 13px;
        }
      }
    }

    &_list {
      display: none;

      &.is-selected {
        display: block;
      }
    }

    &_question {
      position: relative;
      border-bottom: 1px solid #e6e6e6;

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 34px;
        left: 0;
        @include sp-desktop('ico_question');

        @include mobile {
          top: 20px;
          @include sp-mobile('ico_question');
        }
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 30px;
        @include sp-desktop('ico_dropdown');
        transform: translateY(-50%);
        transition: transform 0.3s;

        @include mobile {
          right: 0;
        }
      }

      &.is-show {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    &_btn {
      width: 100%;
      padding: 36px 50px 33px 40px;
      font-size: 18px;
      color: #333;
      text-align: left;

      @include mobile {
        padding: 17px 28px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &_answer {
      display: none;
      padding: 36px 40px 35px;
      background-color: #f9f9f9;
      border-bottom: 1px solid #e6e6e6;

      @include mobile {
        padding: 16px 22px;
        border-bottom: 1px solid #dedede;
      }
    }

    &_disc {
      font-weight: 400;
      font-size: 18px;
      color: #333;
      line-height: 30px;

      @include mobile {
        font-size: 14px;
        line-height: 24px;
        color: #848484;
      }
    }

    &_link {
      border-bottom: 1px solid #333;

      @include mobile {
        border-bottom: 1px solid #848484;
      }
    }
  }
}
