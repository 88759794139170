/* border-radius */
@mixin border-radius($rad) {
	-o-border-radius: $rad;
	-ms-border-radius: $rad;
	-moz-border-radius: $rad;
	-webkit-border-radius: $rad;
	border-radius: $rad;
}

@mixin box-sizing {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

$vendors: webkit, moz;
@mixin vender-prefix($name, $value) {
    @each $vendor in $vendors {
        -#{$vendor}-#{$name}: $value;
    }
    #{$name}: $value;
}

@mixin ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin ellipsis2 {
	display: block;
	overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

@mixin clear {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

@mixin hover {
    @media screen and (min-width: 1280px) {
        &:hover {
            @content;
        }
    }
} //pc만 hover효과 적용

@mixin mobile {
    @media all and (max-width: 767px){
        @content;
    }
}

@mixin tablet  {
    @media only screen and (min-width: 768px) and (max-width: 1279px) {
        @content;
    }
}

@mixin tablet2  {
    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
        @content;
    }
}

@mixin media-query($width) {
    @media screen and (max-width: #{$width}px) {
        @content;
    }
}
