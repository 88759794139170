body,
html {
	width: 100%;
	height: 100%;
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
	-webkit-overflow-scrolling: touch;
	font-family: NanumSquare, -apple-system, BlinkMacSystemFont, '나눔스퀘어', '나눔고딕', NanumGothic, '맑은고딕', MalgunGothic, '돋움', Dotum, Helvetica, sans-serif;
	font-weight: 800;
    font-size: 15px;
    color: #000;
    line-height: 1.2;
}

body,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
select,
table,
td,
textarea,
th,
ul {
	margin: 0;
	padding: 0;
}

fieldset {
	border: 0;
}

img,
video {
	border: 0;
	vertical-align: top;
}

ol,
ul {
	list-style: none;
}

li {
	vertical-align: top;
	list-style: none;
}

textarea {
	-webkit-border-radius: 0;
	border-radius: 0;
	-webkit-appearance: none;
	resize: none;
}

address,
em {
	font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6, 
strong {
	font-weight: 800;
 }

input,
select {
	vertical-align: middle;
}

input[type=text]::-ms-clear {
	display: none;
}

button,
input {
	padding: 0;
	border: none;
	border: 0;
	outline: none;
	outline: 0;
	background: none;
	font: inherit;
	background-color: transparent;
	-webkit-appearance: none;
	appearance: none;
	-webkit-border-radius: 0;
	border-radius: 0;
	cursor: pointer;
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

a:active,
a:focus,
a:hover {
	text-decoration: none;
	outline: none;
}

.blind {
	overflow: hidden;
	position: absolute;
	clip: rect(0,0,0,0);
	width: 1px;
	height: 1px;
	margin: -1px;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
	display: block;
}

table {
	width: 100%;
	table-layout: fixed;
	padding: 0;
	border-spacing: 0;
	border: 0;
	border-collapse: collapse;

	caption {
		visibility: hidden;
	}
}

.pc_br,
.tb_br,
.m_br,
.pc_show,
.tb_show,
.m_show {
	display: none;
}

.pc_br, .pc_show {
	display: block;

	@include media-query(1279) {
		display: none;
	}
}

.tb_br, .tb_show {
	@include tablet {
		display: block;
	}
}

.m_br {
	@include media-query(390) {
		display: block;
	}
}

.m_show {
	@include mobile {
		display: block;
	}
}

#one-viewer {
    font-weight: initial;
    font-family: initial;
    font-size: initial;
    color: initial;
    line-height: initial;
}