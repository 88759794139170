.dimmed {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.6;
    z-index: 100;
}

.ly_popup_wrap {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    z-index: 1000;
    text-align: center;
    font-size: 0;
    white-space: nowrap;
    
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

.ly_popup {
    position: relative;
    display: inline-block;
    width: 1160px;
    margin: 30px 40px;
    padding: 68px;
    border: 1px solid #000;
    background-color: #fff;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    @include box-sizing;

    @include media-query(1279) {
        width: 100%;
        min-height: 100%;
        margin: 0;
        border: 0;
    }

    @include tablet {
        padding: 28px 32px 62px;
    }
    
    @include mobile {
        padding: 27px 24px 60px;
    }
}

.popup_content {
    position: relative;
    @include clear;

    @include media-query(1279) {
        &:after {
            display: none;
        }
    }

    .left_box {
        float: left;

        @include media-query(1279) {
            float: none;
        }
    }

    .right_box {
        float: right;

        @include media-query(1279) {
            float: none;
        }
    }

    .popup_title {
        font-size: 36px;
        line-height: 40px;
        letter-spacing: -1px;

        @include media-query(1279) {
            font-size: 22px;
            line-height: 24px;
            letter-spacing: -0.46px;
        }

        .period {
            display: block;
            margin-top: 8px;
            font-size: 18px;
            line-height: 32px;
            letter-spacing: -0.5px;
            color: #bfbfbf;

            @include media-query(1279) {
                display: inline-block;
                margin: 0 0 0 9px;
                font-size: 20px;
                line-height: 22px;
                letter-spacing: -0.56px;
                vertical-align: top;
            }
        }
    }

    .history_info {
        width: 643px;

        @include media-query(1279) {
            width: 100%;
        }

        @include tablet {
            margin-top: 64px;
        }

        @include mobile {
            margin-top: 44px;
        }

        dl {
            position: relative;

            &:first-of-type {
                dt {
                    padding-top: 4px;
                }

                dd {
                    padding-top: 0;
                }

                dd + dd {
                    padding-top: 7px;
                }
            }
        }
        
        dt {
            position: absolute;
            top: 0;
            left: 0;
            width: 177px;
            height: 100%;
            padding-top: 52px;
            border-left: 2px solid #333;
            border-right: 1px solid #dedede;
            font-family: Calibre;
            font-weight: 700;
            font-size: 26px;
            color: #1ace5e;
            text-align: center;
            line-height: 26px;
            @include box-sizing;

            @include media-query(1279) {
                text-align: left;
                border: 0;
            }

            @include tablet {
                width: 122px;
                padding-top: 44px;
            }

            @include mobile {
                width: 59px;
                padding-top: 42px;
                font-size: 20px;
                line-height: 20px;
            }
        }

        dd {
            padding: 48px 0 0 176px;
            font-size: 15px;
            line-height: 26px;

            @include tablet {
                padding: 40px 0 0 122px;
            }

            @include mobile {
                padding: 37px 0 0 118px;
                font-size: 14px;
                line-height: 24px;
            }

            + dd {
                padding-top: 8px;

                @include mobile {
                    padding-top: 10px;
                }
            }
        }

        .month {
            float: left;
            width: 98px;
            margin-right: 20px;
            text-align: right;

            @include tablet {
                width: 34px;
            }

            @include mobile {
                position: absolute;
                left: 60px;
                width: 37px;
            }

            .num {
                display: inline-block;
                font-family: Calibre;
                font-weight: 600;
                font-size: 21px;
                vertical-align: top;
                margin-top: -1px;

                @include mobile {
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            .txt {
                display: inline-block;
                vertical-align: top;
            }
        }

        .history_txt {
            display: inline-block;
            overflow: hidden;
            color: #848484;
            vertical-align: top;
            
            span {
                display: block;

                & + span {
                    @include tablet {
                        margin-top: 9px;
                    }
                    
                    @include mobile {
                        margin-top: 7px;
                    }
                }
            }
        }
    }

    .dropdown_box {
        position: absolute;
        top: 0;
        right: 40px;

        @include media-query(1279) {
            position: relative;
            right: auto;
        }

        @include tablet {
            margin-top: 60px;
        }

        @include mobile {
            margin-top: 37px;
        }

        .btn_dropdown {
            padding: 7px 0 2px;
            font-family: Calibre;
            font-weight: 700;
            font-size: 26px;
            line-height: 26px;

            @include mobile {
                padding: 2px 0 5px;
                font-size: 20px;
                line-height: 20px;
            }
        }

        .dropdown_list {
            .item {
                &:hover {
                    .link {
                        padding: 13px 16px 6px;
                        font-weight: 700;
                    }
                }
            }

            .link {
                padding: 10px 16px 9px;
                font-family: Calibre;
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
            }
        }
    }

    .financial_info {
        width: 980px;
        margin: 91px auto 0;
        @include clear;

        @include media-query(1279) {
            width: auto;

            &:after {
                display: none;
            }
        }

        @include tablet {
            margin-top: 48px;
        }

        @include mobile {
            margin-top: 45px;
        }

        .info_box {
            float: left;
            @include media-query(1279) {
                float: none;
                text-align: center;
            }

            @include tablet {
                .tb_show {
                    display: inline-block;
                }
            }

            @include mobile {
                .m_show {
                    display: inline-block;
                }

                .tb_show {
                    display: none;
                }
            }

            + .info_box {
                position: relative;
                margin-left: 100px;

                @include media-query(1279) {
                    margin: 0 auto;
                }

                @include tablet {
                    margin-top: 97px;
                }

                @include mobile {
                    margin-top: 100px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -50px;
                    width: 1px;
                    height: 100%;
                    background-color: #dedede;

                    @include media-query(1279) {
                        width: auto;
                        height: 1px;
                    }

                    @include tablet {
                        top: -49px;
                        left: -32px;
                        right: -32px;
                    }

                    @include mobile {
                        top: -50px;
                        left: -24px;
                        right: -24px;
                    }
                }
            }

            img {
                @include mobile {
                    max-width: 296px;
                    max-height: 204px;
                }
            }
        }

        & + .btn_box {
            margin-top: 99px;

            @include tablet {
                margin: 83px 0 45px;
            }

            @include mobile {
                margin: 61px 0 0;
                text-align: center;
            }
        }
    }
}